import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ShareService } from '../shared/share.service';
import { Subscription } from 'rxjs';
import { NbSidebarService } from '@nebular/theme';
import { NbMenuItem } from '@nebular/theme';
import { expand } from 'rxjs/operators';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  affiliateUserSubscription: Subscription;
  windowWidth;
  mobile;
  showUserName = false;
  userName;

  items: NbMenuItem[] = [
    {
      title: 'Dashboard',
      link: '/',
      icon: 'home-outline',
      home: true,
      expanded: true
    },
    {
      title: 'Leads',
      children: [
        {
          title: 'New Lead',
          link: '/lead/new',
          icon: 'plus-circle'

        },
        {
          title: 'Lead List',
          link: '/lead/list',
          icon: 'list'
        }
      ],
      icon: 'people'
    },

    {
      title: 'My Upwards-URL Leads',
      link: '/attribution',
      icon: 'link'
    },
    {
      title: 'Help & Demo',
      link: '/help',
      icon: 'question-mark-circle',
    }
  ];

  constructor(private authService: AuthService,
    private router: Router,
    private shareService: ShareService,
    private sidebarService: NbSidebarService
  ) {
    this.affiliateUserSubscription = this.shareService.isAffiliateUser.subscribe(res => {
      if (res) {
        this.items[1] = {
          title: 'Leads',
          icon: 'people',
          children: [
            {
              title: 'Lead List',
              link: '/lead/list',
              icon: 'list'
            }
          ],
        };
        this.items.splice(3, 1);
      }
    })
  }
  onclick() {
    const windowWidth = window.screen.width < window.outerWidth ?
      window.screen.width : window.outerWidth;
    const mobile = windowWidth < 1025;
    if (mobile) {
      this.sidebarService.collapse('menu-sidebar');
    }
  }

  ngOnInit() {
    this.userName = localStorage.getItem('Name');
    this.windowWidth = window.screen.width < window.outerWidth ?
      window.screen.width : window.outerWidth;
    this.mobile = this.windowWidth < 768;
    if (this.mobile) {
      this.showUserName = true;

    }

  }

}
