import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '../shared/share.service';
import { appConstants } from '../shared/constants';
import { NbSidebarService } from '@nebular/theme';
import { LayoutService } from '../services/layout.service';


@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {

  constructor(private shareService: ShareService,
    private cdr: ChangeDetectorRef,private sidebarService: NbSidebarService,private layoutService: LayoutService) { }
  loading = false;
  loaderListener: Subscription;
  version = appConstants.version;


  ngOnInit() {
    this.shareService.loader.subscribe(isLoading => {
      this.loading = isLoading;
      this.cdr.detectChanges()
    })
  }

}
