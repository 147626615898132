import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { appConstants, apiConstants } from './constants';
import { map } from 'rxjs/operators';
import { ShareService } from './share.service';
import { Observable } from 'rxjs';

interface ResponseInterface {
  meta: object;
  data: object;
};

@Injectable({
  providedIn: 'root'
})
export class ServerRequestConfigService {
  requestHeaders: HttpHeaders;
  noHeadersRequiredUrls = [apiConstants.socialLogin.url, apiConstants.displayData.url];
  isAffiliate = false;
  platformPrefix = '/as';

  constructor(private http: HttpClient,
    private router: Router,
    private shareService: ShareService) {
    this.router.events.subscribe(event => {
      if (event) {
        if (this.router.url.includes('affiliate')) {
          this.isAffiliate = true;
          this.platformPrefix = '/af';
        } else {
          this.isAffiliate = false;
          this.platformPrefix = '/as';
        }
        if (window.location.href.includes('affiliate')) {
          this.isAffiliate = true;
          this.platformPrefix = '/af';
        } else {
          this.isAffiliate = false;
          this.platformPrefix = '/as';
        }
      }
    })
    // this.additionalHeaders.append('Admin-id', this.localStorageService.get('user_id'));
  }
  ngAfterViewInit() {

  }
  setHeaders() {
    if (localStorage.getItem('User-Session-Token')) {
      this.requestHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('User-Session-Token'),
        'User-Id': localStorage.getItem('User-Id'),
      });
    } else if(localStorage.getItem('Login-URL')) {
      let loginUrl = localStorage.getItem('Login-URL');
      let loginRoute = loginUrl.slice(1);
      this.router.navigate([loginRoute]);
    } else {
      // if (this.isAffiliate) {
      //   this.router.navigate(['affiliate', 'login']);
      // } else {
      this.router.navigate(['login']);
      // }
    }
  }
  get(apiUrl: string, requestParams?: HttpParams | {}) {
    if (this.noHeadersRequiredUrls.includes(apiUrl)) {
      return this.http.get(appConstants.prodApiBaseUrl + this.platformPrefix + apiUrl, { params: requestParams }).pipe(map((res: ResponseInterface) => res.data));
    }
    this.setHeaders();
    return this.http.get(appConstants.prodApiBaseUrl + this.platformPrefix + apiUrl, { headers: this.requestHeaders, params: requestParams }).pipe(map((res: ResponseInterface) => res.data));
  }
  post(apiUrl: string, payLoad: object): Observable<any> {
    // this.additionalHeaders.append('Session-Token', this.localStorageService.get('Associate-User-Session-Token'));  
    if (this.noHeadersRequiredUrls.includes(apiUrl)) {
      return this.http.post(appConstants.prodApiBaseUrl + this.platformPrefix + apiUrl, payLoad).pipe(map((res: ResponseInterface) => res.data));
    }
    this.setHeaders();
    return this.http.post(appConstants.prodApiBaseUrl + this.platformPrefix + apiUrl, payLoad, { headers: this.requestHeaders }).pipe(map((res: ResponseInterface) => res.data));
  }
  put(apiUrl: string, payLoad: object, uniqueId?: any) {
    this.setHeaders();
    return this.http.put(appConstants.prodApiBaseUrl + this.platformPrefix + apiUrl + (uniqueId ? uniqueId + '/' : ''), payLoad, { headers: this.requestHeaders })
  }
}
