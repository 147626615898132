import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeLayoutComponent } from './layouts/home-layout.component';
import { HomeComponent } from './home/home.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { LeadComponent } from './leads/lead/lead.component';
import { LeadListComponent } from './leads/lead-list/lead-list.component';
import { NewLeadComponent } from './leads/new-lead/new-lead.component';
import { AttributionComponent } from './attribution/attribution.component';
import { HelpComponent } from './help/help.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LendingkartLoginComponent } from './lendingkartlogin/lendingkartlogin.component';
import { LeadBureauComponent } from './leads/lead-bureau/lead-bureau.component';


const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivateChild: [AuthGuard],
    data: {
      breadcrumb: null
    },
    children: [

      {
        path: '',
        component: HomeComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'lead/list',
        component: LeadListComponent,
        data: {
          breadcrumb: 'Lead List'
        }
      },
      {
        path: 'lead/new',
        component: NewLeadComponent,
        data: {
          breadcrumb: 'New Lead'
        }
      },
      // {
      //   path: 'lead/:customerId/:loanId',
      //   component: LeadComponent,
      //   data: {
      //     breadcrumb: 'Lead'
      //   }
      // },
      {
        path: 'lead/:customerId/:loanId',
        component: LeadComponent,
        data: {
          breadcrumb: 'Lead'
        }
      },
      {
        path: 'leads/archivable/:isArchivable/:emailId/:pan',
        component: LeadComponent,
        data: {
          breadcrumb: 'Re-application'
        }
      },
      {
        path: 'attribution',
        component: AttributionComponent,
        data: {
          breadcrumb: 'My Upwards URL Leads'
        }
      },
      {
        path: 'help',
        component: HelpComponent,
        data: {
          breadcrumb: 'Help'
        }
      },
      
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: {
          breadcrumb: 'Login'
        }
      },    
    ]
  },  
  {
    path: 'lendingkart/login',
    component: LendingkartLoginComponent, 
    data: {
      breadcrumb: 'Lendingkart Login'
    }       
  }, 
  {
    path:'',
    redirectTo: 'lendingkart/login',
    pathMatch: 'full',    
  },   
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      breadcrumb: 'Not Found'
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
