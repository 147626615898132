import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { appConstants } from 'src/app/shared/constants';
import { CreateLeadResponse, LeadSearchResponse, DedupeResponse } from 'src/app/shared/interfaces';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { ShareService } from 'src/app/shared/share.service';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.scss']
})
export class NewLeadComponent implements OnInit {
  custPrimaryDataFormGroup: FormGroup;
  customerCheckLoading = false;
  mySourceId = +localStorage.getItem('Source-Id');
  dropDownData: object;
  emailId: string;
  pan: string;

  constructor(private formBuilder: FormBuilder,
    private apiRequest: ApiRequestService,
    private router: Router,
    private toastr: NbToastrService,
    private shareService: ShareService) { }

  ngOnInit() {
    this.apiRequest.getDisplayData().subscribe(response => {
      this.dropDownData = response;
    })
    this.custPrimaryDataFormGroup = this.formBuilder.group({
      social_email_id: ['', [Validators.required, Validators.pattern(appConstants.regexes.email)]],
      pan: ['', [Validators.required, Validators.pattern(appConstants.regexes.pan)]]
    });
  }

  checkCustomer(): void {

    this.emailId = this.custPrimaryDataFormGroup.value.social_email_id.toLowerCase();
    this.pan = this.custPrimaryDataFormGroup.value.pan;

    this.emailId = this.removeSpace(this.emailId);

    const verifiedDomainName = this.verifiedDomainName(this.emailId);

    if (!verifiedDomainName) {
      this.toastr.danger('Please check the social email!', 'Invalid Email');
      return;
    }

    this.customerCheckLoading = true;
    const postData = {
      social_email_id: this.emailId,
      pan: this.pan,
      // filter_operator: 'OR'
    };
    this.apiRequest.checkLead(postData).subscribe((custDataResponse: DedupeResponse[]) => {
      if (custDataResponse && custDataResponse.length) {
        if (custDataResponse.length === 1) {
          if (custDataResponse[0].source_id === this.mySourceId
            || (this.dropDownData['whitelisted_source_ids'][this.mySourceId])) {
            let custData = custDataResponse[0];
            handleResponse.call(this, custData);
          } else {
            this.customerCheckLoading = false;
            let custData = custDataResponse[0];
            if (custData && custData['customer_loan_archive_eligibility']) {
              handleArchiveResponse.call(this, custData);
            } else {
              this.toastr.info(custData['ineligibility_archive_reason'], "Duplicate Lead");
            }
          }
        } else {
          this.customerCheckLoading = false;
          this.toastr.info("Multiple Leads already exist with given data", "Duplicate Leads");
        }
      } else {
        this.apiRequest.createCustomer(postData).subscribe((response: CreateLeadResponse) => {
          handleResponse.call(this, response);
        })
      }
      function handleResponse(response) {
        this.customerCheckLoading = false;
        this.customerId = +response.customer_id;
        this.loanId = +response.loan_id;
        this.router.navigate([`lead/${this.customerId}/${this.loanId}`]);
      }
      function handleArchiveResponse(response) {
        this.customerCheckLoading = false;
        this.isArchivable = response.customer_loan_archive_eligibility;
        this.router.navigate([`leads/archivable/${this.isArchivable}/${this.emailId}/${this.pan}`]);
      }
    });
  }

  verifiedDomainName(email: string) {
    const emailRegex = /@(\w+)/i;
    const domain: any[] = emailRegex.exec(email);

    if (domain !== null) {
      const invalidDomains = ['gamail', 'gamil', 'gmai', 'gmali', 'redifmail', 'gmil', 'ymil', 'yahooo'];
      if (invalidDomains.includes(domain[1])) {
        return false;
      } else {
        return true;
      }
    }
  }


  removeSpace(emailId) {
    return emailId.split(' ').join('');
  }
}
