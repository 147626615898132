import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ApiRequestService } from '../shared/api-request.service';
import { ShareService } from '../shared/share.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { appConstants } from '../shared/constants';
import { forkJoin } from 'rxjs';
import { NbSidebarService } from '@nebular/theme';
import { LayoutService } from '../services/layout.service';

declare const gapi: any;

interface User {
  name: string;
  email: string;
  imageUrl?: string;
  userId?: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  user: User = {
    name: '',
    email: '',
    userId: ''
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }


  constructor(private authService: AuthService,
    private apiRequest: ApiRequestService,
    private shareService: ShareService,
    private deviceService: DeviceDetectorService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService
    ) { }

  ngOnInit() {
    let userName = localStorage.getItem('Name');
    let userEmail = localStorage.getItem('Email');
    let userId = localStorage.getItem('User-Id');
    this.user.name = userName || 'User';
    this.user.email = userEmail || '';
    this.user.userId = userId || '';

  }
  ngAfterViewInit() {
    setTimeout(() => {
      try {
        gapi.load('auth2', () => {
          this.authService.setGoogleAuthInstance(gapi.auth2.init());
        })
      } catch (err) {

      }
    }, 1500);
  }
  logout() {
    this.shareService.showSpinner();
    this.apiRequest.associateLogout().subscribe(res => {
      this.shareService.hideSpinner();
      this.authService.logout();
      this.adminActionLogout();
    }, err => {
      this.shareService.hideSpinner();
      this.authService.logout();
      this.adminActionLogout();
    })
  }

  private adminActionLogout() {
    let deviceInfo = this.getDeviceInfo();

    let dateTime = new Date(new Date().valueOf() - new Date().getTimezoneOffset() * 60000).toISOString();
    let adminData = {
      user_type: 'Associate',
      user_name: this.user.name || 'User',
      email_id: this.user.email || '',
      user_id: this.user.userId || '',
      action: appConstants.adminActions.logout,
      action_start_datetime: dateTime,
      action_start_status_id: appConstants.adminActionStatus.started,
      device_type: deviceInfo['deviceType'],
      product_type_id: appConstants.productType,
      client: deviceInfo['browser'],
      client_version: deviceInfo['browser_version'],
      client_os: deviceInfo['os']
    }

    this.apiRequest.getIpAddressData('').subscribe((res) => {
      if (res && res['status'] === 'success') {
        adminData['client_latitude'] = res['lat'];
        adminData['client_longitude'] = res['lon'];
        adminData['client_zipcode'] = res['zip'];
        adminData['isp'] = res['isp'];
        adminData['city'] = res['city'];
        adminData['country'] = res['country'];
      }
      this.apiRequest.logAdminAction(adminData).subscribe((res) => { });
    });
  }

  private getDeviceInfo() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    let deviceType = appConstants.deviceType.desktop;
    if (this.deviceService.isTablet()) {
      deviceType = appConstants.deviceType.tablet;
    } else if (this.deviceService.isMobile()) {
      deviceType = appConstants.deviceType.mobile;
    }
    deviceInfo['deviceType'] = deviceType;
    return deviceInfo;
  }
}
