import { Injectable } from '@angular/core';
import { ServerRequestConfigService } from './server-request-config.service';
import { appConstants, apiConstants } from './constants';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { OrganizationDetailResponse, SummaryDataResponse } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  displayData: object;
  displayDataObservable: Observable<object>;
  organizationData: OrganizationDetailResponse;
  organizationDataObservable: Observable<OrganizationDetailResponse>;

  constructor(private reqConfig: ServerRequestConfigService,
    private http: HttpClient) { }
  associateLogin(googleToken: string) {
    let postData = {
      google_token: googleToken
    };
    return this.reqConfig.post(apiConstants.socialLogin.url, postData);
  }
  associateLogout() {
    return this.reqConfig.post(apiConstants.socialLogout.url, {});
  }
  otpBasedLogin(otp: string, emailId: string) {
    return this.reqConfig.post(apiConstants.otpLogin.url, { email_id: emailId, otp: otp });
  }
  getOtp(emailId: string) {
    return this.reqConfig.post(apiConstants.generateOtp.url, { email_id: emailId });
  }
  getDisplayData() {
    if (this.displayData) {
      return of(this.displayData);
    } else if (this.displayDataObservable) {
      return this.displayDataObservable;
    } else {
      this.displayDataObservable = this.reqConfig.get(apiConstants.displayData.url)
        .pipe(map(response => {
          if (response) {
            this.displayData = response;
            return this.displayData;
          }
        }))
      return this.displayDataObservable;
    }
  }
  getOrganizationDetails() {
    if (this.organizationData) {
      return of(this.organizationData);
    } else if (this.organizationDataObservable) {
      return this.organizationDataObservable;
    } else {
      this.organizationDataObservable = this.reqConfig.post(apiConstants.organizationDetails.url, {})
        .pipe(map((response: OrganizationDetailResponse) => {
          if (response) {
            this.organizationData = response;
            return this.organizationData;
          }
        }))
      return this.organizationDataObservable;
    }
  }
  createCustomer(postData) {
    return this.reqConfig.post(apiConstants.createCustomer.url, postData);
  }
  updateCustomerData(postData) {
    let loanId = postData['loan_id'];
    let customerId = postData['customer_id']
    return this.reqConfig.post(`/v1/customer/${customerId}/loan/${loanId}/data/update/`, postData);
  }
  getIfscData(ifsc: string) {
    return this.http.get(`https://ifsc.razorpay.com/${ifsc}`);
  }
  getPincodeData(pincode: number) {
    return this.reqConfig.get(apiConstants.pincode.url + pincode + '/');
  }
  getUploadedDocsStatus(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post(apiConstants.customerDocumentsStatus.url, postData);
  }
  getDocUploadUrl(customerId: number, loanId: number, docTypeId: number, fileExt: string, password?: string) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: docTypeId,
      password: password,
      document_extension: fileExt
    }
    return this.reqConfig.post(apiConstants.documenetUploadUrl.url, postData);
  }

  getDocUploadUrlForAffiliate(customerId: number, loanId: number, docTypeId: number, fileExt: string, password?: string) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: docTypeId,
      password: password,
      document_extension: fileExt
    }
    return this.reqConfig.post(apiConstants.generateDocumentUploadUrlForAffiliate.url, postData);
  }

  uploadFileToUrl(url: string, base64Data: ArrayBuffer | string, docUploadObj: object) {
    return this.http.put(url, base64Data, { reportProgress: true, observe: 'events' }).pipe(map((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request sent!');
          break;
        case HttpEventType.UploadProgress:
          const kbLoaded = Math.round((event.loaded / event.total) * 100);
          // this.pendingDocs[index].isUploading = true;
          docUploadObj['completionPercent'] = kbLoaded;
          // console.log(`Upload in progress! ${ kbLoaded }Kb loaded`);
          break;
        case HttpEventType.Response:
          console.log('Done!', event.body);
          return true;
        // break;
      }
    }));
  }
  updateDocStatus(customerId: number, loanId: number, docTypeId: number, status: string) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: docTypeId,
      status: status
    };
    return this.reqConfig.post(apiConstants.documentUploadStatus.url, postData);
  }

  updateDocStatusForAffiliate(customerId: number, loanId: number, docTypeId: number, status: string) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: docTypeId,
      status: status
    };
    return this.reqConfig.post(apiConstants.getDocumentUploadStatusForAffiliate.url, postData);
  }

  getLeadList(filterOptions) {
    return this.reqConfig.post(apiConstants.searchLeads.url, filterOptions);
  }
  checkLead(filterOptions) {
    return this.reqConfig.post(apiConstants.dedupe.url, filterOptions);
  }

  getAttributionList(filterOptions) {
    return this.reqConfig.post(apiConstants.associateAttributionSource.url, filterOptions);
  }

  markLoanApplicationComplete(customerId: number, loanId: number, isAffiliate) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
   
    if (isAffiliate) {
      return this.reqConfig.post(apiConstants.applicationCompleteForAffilate.url, postData);
    } else {
      return this.reqConfig.post(apiConstants.customerApplicationSubmitAssociate.url, postData);
    }
  }
  markDocumentSubmitState(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post(apiConstants.documentSubmitStateUpdate.url, postData);
  }
  markCustomerDetailSubmitState(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post(apiConstants.detailSubmitStateUpdate.url, postData);
  }
  getReferralLeadSummary(sourceIds?: number[], startDate?: string, endDate?: string): Observable<SummaryDataResponse> {
    const postObj = {
      source_ids: sourceIds && sourceIds.length ? sourceIds : undefined,
      start_date: startDate,
      end_date: endDate
    }

    if (!postObj.start_date) {
      delete postObj.start_date;
    }

    if (!postObj.end_date) {
      delete postObj.end_date;
    }

    return this.reqConfig.post(apiConstants.referralSummary.url, postObj);
  }
  getCrmLeadSummary(sourceIds?: number[], startDate?: string, endDate?: string): Observable<SummaryDataResponse> {
    const postObj = {
      source_ids: sourceIds && sourceIds.length ? sourceIds : undefined,
      start_date: startDate,
      end_date: endDate
    };

    if (!postObj.start_date) {
      delete postObj.start_date;
    }

    if (!postObj.end_date) {
      delete postObj.end_date;
    }
    
    return this.reqConfig.post(apiConstants.dataSummary.url, postObj);
  }

  logAdminAction(adminData: object) {
    return this.reqConfig.post(apiConstants.adminActionLogging.url, adminData);
  }

  updateAdminActionLog(adminData: object) {
    return this.reqConfig.put(apiConstants.adminActionLogging.url, adminData, adminData['id']);
  }

  getIpAddressData(ipAddress?: string) {
    return this.http.get('http://ip-api.com/json/' + ipAddress);
  }

  getRejectionReasons(loanIds: number[]) {
    return this.reqConfig.post(apiConstants.rejectionReasons.url, { loan_ids: loanIds });
  }

  getAffiliateDocumentStatus(customerId: number, loanId: number) {
    const postObj = {
      loan_id: loanId,
      customer_id: customerId
    }
    return this.reqConfig.post(apiConstants.documentUploadStatusForAffiliate.url, postObj);
  }
  markDocumentSubmitStateForAffiliate(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post(apiConstants.documentSubmitStateUpdateForAffiliate.url, postData);
  }
  fetchS3UrlForDocumentView(customerId: number, loanId: number, documentId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: documentId
    };
    return this.reqConfig.post(apiConstants.documentViewUrl.url, postData);
  }
  fetchS3UrlForDocumentViewForAffiliate(customerId: number, loanId: number, documentId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId,
      document_type_id: documentId
    };
    return this.reqConfig.post(apiConstants.documentViewUrlForAffiliate.url, postData);
  }
  fetchAttributionLink(){
    let postData ={
      "limit":20,
      "offset":0
    }
    return this.reqConfig.post(apiConstants.attributionLink.url,postData);
  }

  markDocumentSubmitStateBureau(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post(apiConstants.documentSubmitStateUpdateBureau.url, postData);
  }

  fetchBankingEligibility(customerId: number, loanId: number) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId
    }

    return this.reqConfig.post(apiConstants.underWritingBanking.url, postData);
    // manual_check
    // return of ({
    //         "customer_id": 6882249,
    //         "loan_id": 10290739,
    //         "credit_decision_status": "completed",
    //         "credit_program_completed_data": {
    //             "credit_program_data": {
    //                 "is_eligible": true,
    //                 "offer": {
    //                     "loan_amount": 1000000,
    //                     "loan_tenure": 24
    //                 }
    //             }
    //         },
    //         "api_call_data": {
    //             "call_interval": [
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10,
    //                 10
    //             ],
    //             "call_count": 12
    //         }
    // })
  }

  fetchUnderwritingEligibility(customerId: number, loanId: number) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId
    }

    return this.reqConfig.post(apiConstants.underwritingEligibility.url, postData);
    //decision_deffered

  //   return of({
  //     "customer_id": 6902112,
  //     "loan_id": 10341891,
  //     "credit_decision_status": "processing",
  //     "default_data": {
  //         "loan_amount": 100000,
  //         "loan_tenure": 12
  //     },
  //     "credit_decision_completed_data": {
  //         "bureau_banking_program_mandatory": false,
  //         "bureau_program_data": {
  //             "is_eligible": true,
  //             "offer": {
  //                 "loan_amount": 200000,
  //                 "loan_tenure": 24
  //             },
  //             "credit_program_id": 1
  //         },
  //         "bureau_banking_program_data": {
  //             "is_eligible": false,
  //             "offer": null,
  //             "credit_program_id": 2
  //         },
  //         "sepl_banking_program_data": {
  //             "is_eligible": false,
  //             "offer": null,
  //             "credit_program_id": 3
  //         },
  //         "sepl_bureau_program_data": {
  //             "is_eligible": false,
  //             "offer": {
  //               "loan_amount": 200000,
  //               "loan_tenure": 24
  //           },
  //             "credit_program_id": 5
  //         },
  //         "aip_program_data": {}
  //     },
  //     "api_call_data": {
  //         "call_interval": [
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10,
  //             10
  //         ],
  //         "call_count": 12
  //     }
  // });
  }  


  updateAffiliateCustomerData(postData) {
    return this.reqConfig.post(`/v2/portal/customer/loan/data/update/`, postData);
  }

  markAffilicateCustomerDetailSubmitState(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post('/v2/portal/customer/loan/data/complete/', postData);
  }

  fetchAffiliateUnderwritingEligibility(customerId: number, loanId: number) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId
    }

    return this.reqConfig.post('/v2/portal/customer/loan/credit_programs/decision/', postData);
  }

  markAffiliateDocumentSubmitStateBureau(customerId: number, loanId: number) {
    let postData = {
      customer_id: customerId,
      loan_id: loanId
    };
    return this.reqConfig.post('/v2/portal/customer/loan/banking_data/complete/', postData);
  }

  fetchAffiliateBankingEligibility(customerId: number, loanId: number) {
    const postData = {
      customer_id: customerId,
      loan_id: loanId
    }

    return this.reqConfig.post('/v2/portal/customer/loan/credit_programs/offer/', postData);
  }
  
  fetchFHR(customerId: number, loanId: number) {
    const postData = {customer_id: customerId, loan_id: loanId}
    return this.reqConfig.post(`/v2/customer/loan/financial_health_report/data/`, postData);
  }
 
}
