import { Component, OnInit } from '@angular/core';
import { HelpContact } from './../shared/interfaces'
import { ApiRequestService } from '../shared/api-request.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  helpContacts: HelpContact[];
  constructor(private apiRequest: ApiRequestService) { }
  ngOnInit() {
  
    this.apiRequest.getDisplayData().subscribe(res => {
      this.helpContacts = res['help_contact_data'];
    })
   
  }

}
