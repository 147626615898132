import { Component, ElementRef, OnInit } from '@angular/core';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { appConstants } from 'src/app/shared/constants';
import { OrganizationAdmin } from 'src/app/shared/interfaces';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ShareService } from '../shared/share.service';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
declare var require: any
var moment = require('moment');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  date;
  crmDataSummary = {
    total: {
      your_leads: 0
    },
    inComplete: {
      your_leads: 0
    },
    complete: {
      your_leads: 0
    },
    approved: {
      your_leads: 0,
      approved_amount: 0
    },
    rejected: {
      your_leads: 0
    },
    disbursed: {
      your_leads: 0,
      approved_amount: 0
    },
    duplicate: {
      your_leads: 0
    },
    postSanctionReject: {
      your_leads: 0
    },
    postSanctionDropout: {
      your_leads: 0
    },
    allTimeComplete: {
      your_leads: 0
    }
  };
  referralDataSummary = {
    total: {
      your_leads: 0
    },
    inComplete: {
      your_leads: 0
    },
    complete: {
      your_leads: 0
    },
    approved: {
      your_leads: 0,
      approved_amount: 0,
    },
    rejected: {
      your_leads: 0
    },
    disbursed: {
      your_leads: 0,
      approved_amount: 0
    },
    duplicate: {
      your_leads: 0
    },
    postSanctionReject: {
      your_leads: 0
    },
    postSanctionDropout: {
      your_leads: 0
    },
    allTimeComplete: {
      your_leads: 0
    }
  };
  loanStageCategory = appConstants.loanStageCategory;
  haveSubordinates = false;
  filteredSources: string | number;
  allSubordinateIds: string;
  filterStartDate: string;
  filterEndDate: string;
  subordinateOptions: OrganizationAdmin[] = [];
  subordinateLeadSummary = [];
  subordinatesObj = {};
  childType: string;

  crmResponseData = {};
  urlResponseData = {};

  showData = false;
  crmRequestCompleted = false;
  urlRequestCompleted = false;

  crmSubscription: Subscription;
  urlSubscription: Subscription;
  showSEPLjourney: boolean;

  startDateForFilter;
  endDateForFilter;

  adminId;

  constructor(private apiRequest: ApiRequestService,
    private router: Router,
    private shareService: ShareService,
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private elementRef: ElementRef) {}

  ngOnInit() {
    this.date = new FormControl(new Date());
    this.subordinateOptions.length = 0;
    if (this.authService.isAffilliatedUser()) {
      this.childType = 'Affiliate';
    } else {
      this.childType = 'Associate';
    }
    
    this.adminId = +this.authService.getSourceId();

    this.filterStartDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    this.filterEndDate = moment().format('YYYY-MM-DD');
    this.fetchData(this.filterStartDate, this.filterEndDate);

    this.startDateForFilter = moment().subtract(3, 'months').format('YYYY-MM-DD');
    this.endDateForFilter = moment().format('YYYY-MM-DD');
  }

  
  fetchData(startDate, endDate) {
    this.shareService.showSpinner();
    const sourceId = +this.authService.getSourceId();

    this.apiRequest.getOrganizationDetails().subscribe(organizationDetailsRes => {
      this.shareService.hideSpinner();
      // let adminArr = [2265, 2344, 2165, 2180];
      // if(adminArr.includes(organizationDetailsRes.admin_id)) {
      //   this.showSEPLjourney = true;
      //   this.shareService.setSEPLjourney(this.showSEPLjourney);
      // }
      if (organizationDetailsRes && organizationDetailsRes.subordinates && organizationDetailsRes.subordinates.length) {
        const linkedSouceIds = organizationDetailsRes.subordinates.map(adminData => adminData.admin_id);
        this.subordinateOptions = organizationDetailsRes.subordinates.slice();
        let selfDataObj = {
          admin_id: organizationDetailsRes.admin_id,
          email_id: organizationDetailsRes.email_id,
          full_name: organizationDetailsRes.full_name,
          mobile_number: organizationDetailsRes.mobile_number,
          role: organizationDetailsRes.role,
          organization: organizationDetailsRes.organization,
          organization_id: organizationDetailsRes.organization_id,
        };
        this.subordinateOptions.unshift(selfDataObj);
        linkedSouceIds.unshift(organizationDetailsRes.admin_id);
        for (let sourceObj of this.subordinateOptions) {
          this.subordinatesObj[sourceObj.admin_id] = sourceObj;
        }
        this.filteredSources = linkedSouceIds.join(",");
        this.allSubordinateIds = linkedSouceIds.join(",");
        // this.getCRMLeadSummary(linkedSouceIds, startDate, endDate);
        // this.getUrlLeadSummary(linkedSouceIds, startDate, endDate);
      } else {
        // this.getCRMLeadSummary([sourceId], startDate, endDate);
        // this.getUrlLeadSummary([sourceId], startDate, endDate);
      }
    });
  }

  showIncompleteDataApplication(event: MouseEvent) {
    let navigationExtras: NavigationExtras = {
      queryParams: { 'stages': this.loanStageCategory.inComplete }
    };
    this.router.navigate(['/lead', 'list'], navigationExtras);
  }
  searchCrmLeads(sourceId?: string | number, startDate?: string, endDate?: string) {
    let sourceIds: number[];
    if (sourceId) {
      try {
        sourceIds = typeof sourceId === 'string' ? sourceId.split(",").map(id => +id) : [sourceId];
      } catch (err) {
        sourceIds = [+sourceId];
      }
    } else {
      if (this.subordinateOptions.length > 0) {
        sourceIds = this.subordinateOptions.map(subordinateObj => subordinateObj.admin_id);
      } else {
        sourceIds = [+this.authService.getSourceId()];
      }
    }
    this.getCRMLeadSummary(sourceIds, startDate, endDate);
    this.getUrlLeadSummary(sourceIds, startDate, endDate);
  }

  routeToLeadPage(stageCategoty) {
    this.router.navigate(['lead', 'list',
      {
        'stages': this.loanStageCategory[stageCategoty], 'source_ids': this.filteredSources ? this.filteredSources : this.adminId,
        'start_date': this.filterStartDate, 'end_date': this.filterEndDate, 'stage_category': stageCategoty
      }], { relativeTo: this.route })
  }

  routeToAttributionPage(stageCategoty) {
    this.router.navigate(['attribution',
      {
        'stages': this.loanStageCategory[stageCategoty], 'source_ids': this.filteredSources ? this.filteredSources : this.adminId,
        'start_date': this.filterStartDate, 'end_date': this.filterEndDate, 'stage_category': stageCategoty
      }], { relativeTo: this.route })
  }

  private getCRMLeadSummary(sourceIds?: number[], startDate?: string, endDate?: string) {
    this.crmDataSummary = this.getEmptyCRMObject();
    this.crmResponseData = {};
    this.crmRequestCompleted = false;
    this.shareService.showSpinner();
    const crmSourceIds = sourceIds ? [...sourceIds] : null;
    if (crmSourceIds.length != 0) {
      this.getCRMLeadSummaryDataInChunks(crmSourceIds, startDate, endDate, false);
    } else {
      this.getCRMLeadSummaryDataInChunks(crmSourceIds, startDate, endDate, true);
    }
    
  }

  private getCRMLeadSummaryDataInChunks(sourceIds?: number[], startDate?: string, endDate?: string, child?: boolean) {
    const chunkOfSourceIds = sourceIds ? sourceIds.splice(0, 100) : [];
    if (chunkOfSourceIds.length > 0 || child) {
      this.crmSubscription = this.apiRequest.getCrmLeadSummary(chunkOfSourceIds, startDate, endDate).subscribe(res => {
        this.crmResponseData = Object.assign({}, this.crmResponseData, res.loan_data);
        this.getCRMLeadSummaryDataInChunks(sourceIds, startDate, endDate, false);
      });
    } else {
      this.crmRequestCompleted = true;
      if (this.crmRequestCompleted && this.urlRequestCompleted) {
        this.shareService.hideSpinner();
        this.showData = true;
      }
      this.processCRMLeadSummary(this.crmResponseData);
    }
  }

  private processCRMLeadSummary(res) {
    const subLeadSummary = [];
    for (const [sourceId, leadStageCount] of Object.entries(res)) {
      const sourceLeadsSummary = {
        source_id: sourceId,
        total: 0,
        inComplete: 0,
        complete: 0,
        approved: 0,
        rejected: 0,
        disbursed: 0,
        allTimeComplete: 0,
        postSanctionReject: 0,
        postSanctionDropout: 0,
        duplicate: 0
      };
      for (const [loanStage, leadCount] of Object.entries(leadStageCount)) {
        let total = 0;
        for (const [category, loanStages] of Object.entries(this.loanStageCategory)) {
          if (loanStages.includes(loanStage)) {

            this.crmDataSummary[category].your_leads += leadCount.count;
            sourceLeadsSummary[category] += leadCount.count;

            if (category === 'approved' || category === 'disbursed') {
              this.crmDataSummary[category].approved_amount
                += (leadCount.total_approved_amount ? leadCount.total_approved_amount : 0);
            }
            total += leadCount.count;
          }
        }
        sourceLeadsSummary.total += total;
      }

      // all time complete
      sourceLeadsSummary.allTimeComplete = sourceLeadsSummary.complete + sourceLeadsSummary.approved + sourceLeadsSummary.rejected + sourceLeadsSummary.postSanctionDropout + sourceLeadsSummary.postSanctionReject + sourceLeadsSummary.disbursed;

      this.crmDataSummary.total.your_leads += sourceLeadsSummary.total;
      this.crmDataSummary.allTimeComplete.your_leads += sourceLeadsSummary.allTimeComplete;

      subLeadSummary.push(sourceLeadsSummary);
    }

    if (!this.subordinateLeadSummary || !this.subordinateLeadSummary.length) {
      this.subordinateLeadSummary = subLeadSummary;
    }
  }

  private getUrlLeadSummary(sourceIds?: number[], startDate?: string, endDate?: string) {
    this.referralDataSummary = this.getURLEmptyObject();
    this.urlResponseData = {};
    this.urlRequestCompleted = false;
    this.shareService.showSpinner();
    const urlSourceIds = sourceIds ? [...sourceIds] : null;
    if (urlSourceIds.length != 0) {
      this.getUrlLeadSummaryDataInChunks(urlSourceIds, startDate, endDate, false);
    } else {
      this.getUrlLeadSummaryDataInChunks(urlSourceIds, startDate, endDate, true);
    }
    
  }

  private getUrlLeadSummaryDataInChunks(sourceIds?: number[], startDate?: string, endDate?: string, child?: boolean) {
    const chunkOfSourceIds = sourceIds ? sourceIds.splice(0, 500) : [];
    if (chunkOfSourceIds.length > 0 || child) {
      this.urlSubscription = this.apiRequest.getReferralLeadSummary(chunkOfSourceIds, startDate, endDate).subscribe(res => {
        this.urlResponseData = Object.assign({}, this.urlResponseData, res.loan_data);
        this.getUrlLeadSummaryDataInChunks(sourceIds, startDate, endDate, false);
      });
    } else {
      this.urlRequestCompleted = true;
      if (this.crmRequestCompleted && this.urlRequestCompleted) {
        this.shareService.hideSpinner();
        this.showData = true;
      }
      this.processUrlLeadSummary(this.urlResponseData);
    }
  }

  private processUrlLeadSummary(res) {
    const subLeadSummary = [];
    for (const [sourceId, leadStageCount] of Object.entries(res)) {
      const sourceLeadsSummary = {
        source_id: sourceId,
        total: 0,
        inComplete: 0,
        complete: 0,
        approved: 0,
        rejected: 0,
        disbursed: 0,
        duplicate: 0,
        allTimeComplete: 0,
        postSanctionReject: 0,
        postSanctionDropout: 0,
      };
      for (const [loanStage, leadCount] of Object.entries(leadStageCount)) {
        let total = 0;
        for (const [category, loanStages] of Object.entries(this.loanStageCategory)) {
          if (loanStages.includes(loanStage)) {

            this.referralDataSummary[category].your_leads += leadCount.count;
            sourceLeadsSummary[category] += leadCount.count;

            if (category === 'approved' || category === 'disbursed') {
              this.referralDataSummary[category].approved_amount
                += (leadCount.total_approved_amount ? leadCount.total_approved_amount : 0);
            }

            total += leadCount.count;
          }
        }
        sourceLeadsSummary.total += total;
      }

      // all time complete
      sourceLeadsSummary.allTimeComplete = sourceLeadsSummary.complete + sourceLeadsSummary.approved + sourceLeadsSummary.rejected + sourceLeadsSummary.postSanctionDropout + sourceLeadsSummary.postSanctionReject + sourceLeadsSummary.disbursed;

      this.referralDataSummary.allTimeComplete.your_leads += sourceLeadsSummary.allTimeComplete;
      this.referralDataSummary.total.your_leads += sourceLeadsSummary.total;

      subLeadSummary.push(sourceLeadsSummary);
    }
  }

  private getEmptyCRMObject() {
    return {
      total: {
        your_leads: 0
      },
      inComplete: {
        your_leads: 0
      },
      complete: {
        your_leads: 0
      },
      approved: {
        your_leads: 0,
        approved_amount: 0,
      },
      rejected: {
        your_leads: 0
      },
      disbursed: {
        your_leads: 0,
        approved_amount: 0
      },
      duplicate: {
        your_leads: 0
      },
      postSanctionReject: {
        your_leads: 0
      },
      postSanctionDropout: {
        your_leads: 0
      },
      allTimeComplete: {
        your_leads: 0
      }
    };
  }

  private getURLEmptyObject() {
    return {
      total: {
        your_leads: 0
      },
      inComplete: {
        your_leads: 0
      },
      complete: {
        your_leads: 0
      },
      approved: {
        your_leads: 0,
        approved_amount: 0,
      },
      rejected: {
        your_leads: 0
      },
      disbursed: {
        your_leads: 0,
        approved_amount: 0
      },
      duplicate: {
        your_leads: 0
      },
      postSanctionReject: {
        your_leads: 0
      },
      postSanctionDropout: {
        your_leads: 0
      },
      allTimeComplete: {
        your_leads: 0
      }
    };
  }

  ngOnDestroy() {
    if (this.crmSubscription) {
      this.crmSubscription.unsubscribe();
    }
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }
}
