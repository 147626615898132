import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Associate, LoginResponse } from 'src/app/shared/interfaces';
import { ApiRequestService } from '../shared/api-request.service';
// import { NbToastrService } from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { appConstants } from '../shared/constants';
import { forkJoin } from 'rxjs';
import { ShareService } from '../shared/share.service';

declare const gapi: any;
interface otpLoginForm {
  emailId: string;
  otp?: string;
}
interface OtpSentResponse {
  otp_sent_successfully: boolean;
  role?: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  otpLoginEnabled = false;
  isOtpSent = false;
  emailRegex = appConstants.regexes.email;
  isInvalidEmail: boolean;
  associateEmail: string;
  loading = false;
  isInvalidOtp: boolean;
  clientIp: string;
  actionLogId: number;
  version = appConstants.version;

  constructor(private authService: AuthService,
    private ngZone: NgZone,
    private apiRequest: ApiRequestService,
    // private toastr: NbToastrService,
    private deviceService: DeviceDetectorService,
    private shareService: ShareService) {
    /* gapi.load('auth2', function () {
      gapi.auth2.init({

      })
   }); */
  }

  ngOnInit() {
    this.apiRequest.logAdminAction(this.getInitialLogObj()).subscribe((res: object) => {
      this.clientIp = res['client_ip'];
      this.actionLogId = res['id'];
    });
  }
  ngAfterViewInit() {
    if (gapi && gapi.signin2) {
      googleRenderer.call(this);
    } else {
      setTimeout(googleRenderer.bind(this), 1500)
    }
    function googleRenderer() {
      if (!gapi || !gapi.signin2) {
        // this.toastr.danger('Page not loaded correctly, Please refresh', 'Load Error');
        alert("Page not loaded correctly, Please reload")
      }
      gapi.signin2.render('my-signin2', {
        'scope': 'profile email',
        'width': 'auto',
        'border-radius': 5,
        'height': 50,
        'left': 'auto',
        'right': 'auto',
        'longtitle': true,
        'theme': 'light',
        'onsuccess': param => this.onSignIn(param)
      });
    }

  }
  onSignIn(googleUser) {
    this.authService.setGoogleAuthInstance(gapi.auth2.getAuthInstance());
    let profile = googleUser.getBasicProfile();
    let idToken = googleUser.getAuthResponse().id_token;
    this.ngZone.run(() => this.googleSignIn(idToken))
    /* console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail());  */
    // This is null if the 'email' scope is not present.
  }
  googleSignIn(googleToken: string) {
    // login and admin action log requests
    let loginRequest = this.apiRequest.associateLogin(googleToken).subscribe(
      (associateLoginRes: LoginResponse) => {
        if (associateLoginRes.is_valid_user && associateLoginRes.is_authenticated) {
          this.authService.login(associateLoginRes.profile);
        }
        // let combinedData = { ...adminLogRes, ...associateLoginRes }
        
        this.updateLoginAdminData(associateLoginRes, false);
      }, err => {
        
        this.updateLoginAdminData(err, true);
      });
  }
 
  getOtp(formData) {
    this.associateEmail = formData.emailId || this.associateEmail;
    this.isOtpSent = false;
    this.shareService.showSpinner();
    this.loading = true;
    this.apiRequest.getOtp(this.associateEmail).subscribe((res: LoginResponse) => {
      this.shareService.hideSpinner();
      this.loading = false;
      if (res.is_valid_user) {
        this.isOtpSent = true;
        this.isInvalidEmail = false;
      } else {
        // this.toastr.danger("Email is not authorised, Please check email or Contact us");
        this.isInvalidEmail = true;
        this.isOtpSent = false;
      }
    }, err => {
      this.loading = false;
    })
  }

  loginViaOtp(formData: otpLoginForm) {
    const otp = formData.otp;
    this.shareService.showSpinner();
    this.loading = true;
    this.apiRequest.otpBasedLogin(otp, this.associateEmail).subscribe((associateLoginRes: LoginResponse) => {
      this.shareService.hideSpinner();
      this.loading = false;
      if (!associateLoginRes.is_authenticated) {
        this.isInvalidOtp = true;
      } else if (associateLoginRes.is_valid_user && associateLoginRes.is_authenticated) {
        this.authService.login(associateLoginRes.profile);
        this.updateLoginAdminData(associateLoginRes, false);
        this.isInvalidOtp = false;
      }
    }, err => {
      this.loading = false;
      this.updateLoginAdminData(err, true);
    })
  }
  
  private getInitialLogObj() {
    let deviceInfo = this.getDeviceInfo();
    let dateTime = new Date(new Date().valueOf() - new Date().getTimezoneOffset() * 60000).toISOString();
    let preData = {
      user_type: 'Associate',
      action: appConstants.adminActions.login,
      action_start_datetime: dateTime,
      action_start_status_id: appConstants.adminActionStatus.started,
      device_type: deviceInfo['deviceType'],
      product_type_id: appConstants.productType,
      client: deviceInfo['browser'],
      client_version: deviceInfo['browser_version'],
      client_os: deviceInfo['os'],
    }
    return preData;
  }

  private updateLoginAdminData(adminData: object, isFailure = false) {
    // let loginIP = adminData['client_ip'];
    let dateTime = new Date(new Date().valueOf() - new Date().getTimezoneOffset() * 60000).toISOString();
    let postData = {
      action_end_datetime: dateTime,
      action_end_status_id: isFailure ? appConstants.adminActionStatus.failure : appConstants.adminActionStatus.successful
    }

    this.apiRequest.getIpAddressData(this.clientIp).subscribe((res) => {
      if (res && res['status'] === 'success') {
        postData['client_latitude'] = res['lat'];
        postData['client_longitude'] = res['lon'];
        postData['client_zipcode'] = res['zip'];
        postData['isp'] = res['isp'];
        postData['city'] = res['city'];
        postData['country'] = res['country'];
      }

      postData['user_id'] = adminData['profile'] ? adminData['profile']['associate_user_id'] : '';
      postData['email_id'] = adminData['profile'] ? adminData['profile']['email_id'] : '';
      postData['user_name'] = adminData['profile']['name'];
      postData['id'] = this.actionLogId;
      if (this.actionLogId) {
        this.apiRequest.updateAdminActionLog(postData).subscribe((res) => { });
      }
    });
  }

  private getDeviceInfo() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    let deviceType = appConstants.deviceType.desktop;
    if (this.deviceService.isTablet()) {
      deviceType = appConstants.deviceType.tablet;
    } else if (this.deviceService.isMobile()) {
      deviceType = appConstants.deviceType.mobile;
    }
    deviceInfo['deviceType'] = deviceType;
    return deviceInfo;
  }
}
