import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { ShareService } from '../shared/share.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {
    constructor(private toastr: NbToastrService,
                private authService: AuthService,
                private shareService: ShareService,
                private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const started = Date.now();
        let ok: string;

        // extend server response observable with logging
        return next.handle(req).pipe(catchError((err) => {
            this.shareService.hideSpinner();
            if (err.status === 401) {
                this.toastr.danger('Please login again', 'Login Error');
                this.authService.logout();
                // this.router.navigate(['login']);
            } else if (err.status === 400) {
                this.toastr.danger('Please check the data', 'Server Data Error');
            } else if (err.status === 403) {
                // this.toastr.danger('Unauthorized, Please signin using authorized email', 'Forbidden');
                this.authService.logout();
                alert('Unauthorized, Please signin using authorized email');
            } else if (err.status === 404) {
                this.toastr.danger('Something went wrong, Please try again');
                // this.router.navigate(['loan-application']);
            } else if (err.status === 405) {
                this.toastr.danger('Something went wrong');
                this.router.navigate(['loan-application']);
            } else if (err.status === 500) {
                this.toastr.danger('Something went wrong, Please try again', 'Server Error');
            }
            return throwError(err);
        }))

    }
}