import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ResponseErrorInterceptor } from './response-error.interceptor';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '../auth/auth.service';
import { ShareService } from '../shared/share.service';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true, deps: [NbToastrService, AuthService, ShareService] },
];