import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { appConstants } from 'src/app/shared/constants';
import { ApiRequestService } from 'src/app/shared/api-request.service';
import { ErrorStateMatcher } from '@angular/material';
import {
  RazorpayIfscResponse, CreateLeadResponse,
  PincodeResponse, CustomerDetailSubmitStateResponse, DedupeResponse
} from 'src/app/shared/interfaces';
import { FileError } from 'ngxf-uploader';
import { NbToastrService } from '@nebular/theme';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { retry } from 'rxjs/operators';
import { LeadSearchResponse } from 'src/app/shared/interfaces';
import { LeadData } from 'src/app/shared/interfaces';
import { ShareService } from 'src/app/shared/share.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BehaviorSubject, timer } from 'rxjs';
import { Subscription } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { CustomValidators } from 'ngx-custom-validators';
import * as moment from 'moment';
import { AuthService } from '../../auth/auth.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';

declare const require;
const pdfjsLib = require('pdfjs-dist');
// below pdfjs code required for build purpose
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.min');
const pdfjsWorkerBlob = new Blob([pdfjsWorker]);
const pdfjsWorkerBlobURL = URL.createObjectURL(pdfjsWorkerBlob);
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerBlobURL;

export interface PasswordDialogData {
  fileName: string;
  filePassword: string;
  isPasswordInvalid?: boolean;
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-lead-bureau',
  templateUrl: './lead-bureau.component.html',
  styleUrls: ['./lead-bureau.component.scss']
})

export class LeadBureauComponent implements OnInit {
  linear = true;
  ALL_DOC_TYPES = appConstants.documetnTypes;
  bank_branch_details = false;

  kycFormGroup: FormGroup;
  professionFormGroup: FormGroup;
  bankDetailsFormGroup: FormGroup;
  additionalDataFormGroup: FormGroup;

  dropDownData: object;
  // dropDownDataObservable: object;
  salaryPaymentModes: object[];
  employmentStatuses: object[];
  professionTypes: object[];
  yearsOfExpOptions: object[];
  currentEmploymentTenureOptionss: object[];
  genderOptions: object[];
  loanPurposes: object[];
  currentStayCategories: object[];
  residenceTypes: object[];
  maritalStatusOptions: object[];
  companyTypeOptions: object[];
  qualificationOptions: object[];
  relationTypeOptions: object[];
  customerCheckLoading = false;
  customerId: number;
  loanId: number;
  presentState: string;
  isApplicationComplete = false;
  docPasswordObj = {};
  allReuiredDocUploaded = false;
  onlyImages = 'image/png,image/jpeg';
  onlyPdfs = 'application/pdf';
  allExts = 'image/png,image/jpeg,application/pdf';
  docsToUpload = {
    [appConstants.documetnTypes.profilePic]: {},
    [appConstants.documetnTypes.pan]: {},
    [appConstants.documetnTypes.incomeProof1]: {},
    [appConstants.documetnTypes.companyId]: {},
    [appConstants.documetnTypes.aadhaarFront]: {},
    [appConstants.documetnTypes.aadhaarBack]: {},
    [appConstants.documetnTypes.addressProof1]: {},
    [appConstants.documetnTypes.addressProof2]: {},
    [appConstants.documetnTypes.addressProof3]: {},
    [appConstants.documetnTypes.bankStatement1]: {},
    [appConstants.documetnTypes.bankStatement2]: {},
    [appConstants.documetnTypes.bankStatement3]: {},
    [appConstants.documetnTypes.bankStatement4]: {},
    [appConstants.documetnTypes.bankStatement5]: {},
    [appConstants.documetnTypes.bankStatement6]: {},
  };
  requiredDocs = [appConstants.documetnTypes.bankStatement1];
  requiredDocs2 = [appConstants.documetnTypes.profilePic, appConstants.documetnTypes.pan,
    appConstants.documetnTypes.addressProof1, appConstants.documetnTypes.incomeProof1] 
  androidScreen2Docs = [appConstants.documetnTypes.profilePic, appConstants.documetnTypes.pan,
  appConstants.documetnTypes.addressProof1, appConstants.documetnTypes.incomeProof1];
  currentDocType: number;
  filePassword: string;

  isArchived = false;
  socialEmailId: any;
  pan: any;

  isAffiliate: boolean;
  affiliateUserSubscription: Subscription;
  adminId;

  errorMatcher = new CrossFieldErrorMatcher();

  backdropOption = 'static'; 
  keyboardOption = false;
  remainingTime: string;
  interval: any;
  showTimerScreen: boolean = false;
  showRejectedScreen: boolean = false;
  showBankingDetails: boolean = false;
  showBankStatement: boolean = false;
  showOtherDetails: boolean = false;
  showCustomerDocuments: boolean = false;

  showBureau: boolean = false;
  showBureauBanking:boolean = false;
  showBureauBankingBoth: boolean = false;

  presentStage: string;
  offerAmount: any;
  offerTenure: any;
  applicationStatus: any;

  bankVerificationRequired: boolean = false;

  timerOver = false;

  private apiCallSubscription: Subscription;
  private apiResponseObject: any = {};
  removeBankingSubmitButton: boolean = false;
  isBankDocumentUploaded: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private apiRequest: ApiRequestService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: NbToastrService,
    private authService: AuthService,
    private router: Router,
    private shareService: ShareService) {
    this.affiliateUserSubscription = this.shareService.isAffiliateUser.subscribe(res => {
      this.isAffiliate = res;
    });
  }

  ngOnInit() {
    this.apiRequest.getDisplayData().subscribe(response => {
      this.dropDownData = response;
      this.salaryPaymentModes = this.dropDownData['salary_payment_mode'].sort((a, b) => a.serial_no - b.serial_no);
      this.employmentStatuses = this.dropDownData['employment_status'].sort((a, b) => a.serial_no - b.serial_no);
      this.professionTypes = this.dropDownData['profession_type'].sort((a, b) => a.serial_no - b.serial_no);
      this.yearsOfExpOptions = this.dropDownData['total_work_experience_category'].sort((a, b) => a.serial_no - b.serial_no);
      this.currentEmploymentTenureOptionss = this.dropDownData['current_employment_tenure_category'].sort((a, b) => a.serial_no - b.serial_no);
      this.genderOptions = this.dropDownData['gender'].sort((a, b) => a.serial_no - b.serial_no);
      this.loanPurposes = this.dropDownData['loan_purpose'].sort((a, b) => a.serial_no - b.serial_no);
      this.currentStayCategories = this.dropDownData['current_residence_stay_category'].sort((a, b) => a.serial_no - b.serial_no);
      this.residenceTypes = this.dropDownData['residence_type'].sort((a, b) => a.serial_no - b.serial_no);
      this.maritalStatusOptions = this.dropDownData['marital_status_type'].sort((a, b) => a.serial_no - b.serial_no);
      this.qualificationOptions = this.dropDownData['qualification_type'].sort((a, b) => a.serial_no - b.serial_no);
      this.companyTypeOptions = this.dropDownData['organization_type'].sort((a, b) => a.serial_no - b.serial_no);
      if (!this.isAffiliate) {
        this.relationTypeOptions = this.dropDownData['customer_reference_relationship_category'].sort((a, b) => a.serial_no - b.serial_no);
      }
    });
    this.kycFormGroup = this.formBuilder.group({
      first_name: [undefined, Validators.required],
      last_name: [undefined, Validators.required],
      pan: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.pan)]],
      // aadhaar: [undefined, [Validators.pattern(appConstants.regexes.aadhaar)]],
      // aadhaar: [undefined,[aadhaarValidate]],
      gender: [undefined, [Validators.required]],
      dob: [undefined, [Validators.required, CustomValidators.minDate(moment(new Date()).subtract(60, 'years').format('YYYY-MM-DD')), CustomValidators.maxDate(moment(new Date()).subtract(18, 'years').format('YYYY-MM-DD'))]],
      // marital_status_id: [undefined, Validators.required],
      // qualification_type_id: [undefined, Validators.required],
      social_email_id: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.email)]],
      mobile_number1: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.mobile)]],
      current_residence_type_id: [undefined, Validators.required],
      current_residence_stay_category_id: [undefined, Validators.required],
      // current_address_line1: [undefined, Validators.required],
      // current_address_line2: [undefined, Validators.required],
      current_pincode: [undefined, [Validators.required, Validators.max(999999), Validators.pattern(appConstants.regexes.pincode)]],
      current_city: [undefined, Validators.required],
      current_state: [undefined, Validators.required],
      salary: [undefined, [Validators.required, Validators.max(10000000)]],
      salary_payment_mode_id: [undefined, Validators.required],
      profession_type: [],
    });

    function aadhaarValidate(control: AbstractControl): { [key: string]: any } | null {
      const aadhaar: string = control.value;
      var aadhaarRegex = new RegExp(appConstants.regexes.aadhaar);
      var maskedAadhaarRegex = new RegExp(appConstants.regexes.maskedAadhaar);
      if ((aadhaar == '')) {
          console.log("Valid with empty string");
          return null;
      }else if((aadhaar != '') && (aadhaarRegex.test(aadhaar) || maskedAadhaarRegex.test(aadhaar))) {
        console.log("Valid Proper");
        return null;
      }else {
          console.log("Invalid");
          return { 'aadhaar': true };
      }
    }

    this.kycFormGroup.get('current_pincode').valueChanges.subscribe(pincode => {
      if (pincode && pincode.toString().length === 6) {
        this.getCurrentAddressPincodeData(pincode);
      } else {
        this.kycFormGroup.controls.current_city.enable();
        this.kycFormGroup.controls.current_state.enable();
      }
    })
    this.professionFormGroup = this.formBuilder.group({
      salary: [undefined, [Validators.required, Validators.max(10000000)]],
      company: [undefined, Validators.required],
      employment_status_id: [undefined, Validators.required],
      salary_payment_mode_id: [undefined, Validators.required],
      work_email_id: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.email)]],
      profession_type_id: [undefined, Validators.required],
      organization_type_id: [undefined, Validators.required],
      current_company_address_line1: [undefined, Validators.required],
      current_company_address_line2: [undefined, Validators.required],
      current_company_pincode: [undefined, [Validators.required, Validators.max(999999), Validators.pattern(appConstants.regexes.pincode)]],
      current_company_city: [undefined, Validators.required],
      current_company_state: [undefined, Validators.required],
      total_work_experience_category_id: [undefined, [Validators.required]],
      current_employment_tenure_category_id: [undefined, [Validators.required]],
    });
    this.professionFormGroup.get('current_company_pincode').valueChanges.subscribe(pincode => {
      if (pincode && pincode.toString().length === 6) {
        this.getOfficeAddressPincodeData(pincode);
      } else {
        this.professionFormGroup.controls.current_company_city.enable();
        this.professionFormGroup.controls.current_company_state.enable();
      }
    });
    this.bankDetailsFormGroup = this.formBuilder.group({
      account_holder_full_name: [undefined, Validators.required],
      account_number: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.bankAccNumber)]],
      ifsc: [undefined, [Validators.required, Validators.pattern(appConstants.regexes.ifsc)]],
      branch: [undefined, Validators.required],
      city: [undefined, Validators.required],
      district: [undefined, Validators.required],
      state: [undefined, Validators.required],
      contact: [undefined],
      bank_code: [undefined, Validators.required],
      bank: [undefined, Validators.required],
      purpose_id: [undefined, Validators.required],
      expected_amount: [undefined, [Validators.required, Validators.maxLength(7), this.checkExpectedLoanAmount, this.checkExpectedLoanAmountMultiple]]
    });

    this.additionalDataFormGroup = this.formBuilder.group({
      father_first_name: [undefined],
      father_last_name: [undefined],
      mother_first_name: [undefined],
      mother_last_name: [undefined],
      id_1: [undefined],
      reference_relationship_type_id_1: [undefined],
      reference_full_name_1: [undefined],
      reference_mobile_number_1: [undefined, Validators.pattern(appConstants.regexes.mobile)],
      reference_city_1: [undefined],
      id_2: [undefined],
      reference_relationship_type_id_2: [undefined],
      reference_full_name_2: [undefined],
      reference_mobile_number_2: [undefined, Validators.pattern(appConstants.regexes.mobile)],
      reference_city_2: [undefined]
    });

    // check if user is routed from list page
    this.customerId = +this.route.snapshot.paramMap.get('customerId');
    this.loanId = +this.route.snapshot.paramMap.get('loanId');
    this.adminId = +this.authService.getSourceId();

    // if customer is to be archived    
    this.isArchived = JSON.parse(this.route.snapshot.paramMap.get('isArchivable'));
    this.socialEmailId = this.route.snapshot.paramMap.get('emailId');
    this.pan = this.route.snapshot.paramMap.get('pan');

    if (this.isArchived) {
      this.customerArchivedForm();
    }

    if (this.isArchived === false) {
      this.router.navigateByUrl('');
    }

    if (this.customerId && this.loanId) {
      this.shareService.showSpinner();
      this.apiRequest.getLeadList({ loan_id: this.loanId, customer_id: this.customerId }).subscribe((response: LeadSearchResponse) => {
        this.shareService.hideSpinner();

        const list = [];
        for (const key in response.loan_data) {
          list.push(response.loan_data[key]);
        }

        response.loan_data = list;

        if (response.loan_data[0]) {
          this.presentState = response.loan_data[0]['present_state'];
          this.presentStage = response.loan_data[0]['present_stage'] 
          if(this.presentStage == "active_type_2" || this.presentStage == "active_type_3") {
            this.disablePersonalDetailsField();
            this.startCallingEligibilityAPI();
            this.showBankingDetails = true;
          }
          if(response.loan_data[0]['account_number']) {
            console.log('this is working');
            this.disableBankingDetailsField();
            this.showBankingDetails = true;
          }
          if(this.presentStage == "active_type_3") {
            this.isBankDocumentUploaded = true;
            this.bankVerificationRequired = true;
          }
          if (!appConstants.editingAllowedStates.includes(this.presentState)) {
            this.isApplicationComplete = true;
          }
          this.mapCustomerDataToForm(response.loan_data[0]);
          this.kycFormGroup.controls.pan.disable();
          this.kycFormGroup.controls.social_email_id.disable();
        } else {
          this.toastr.danger('No lead found', 'Data Error');
          this.router.navigate(['lead', 'list']);
        }
      });
      if (!this.isAffiliate) {
        this.apiRequest.getUploadedDocsStatus(this.customerId, this.loanId).subscribe(res => {
          if (res && res['document_data']) {
            this.setUploadedDocStatus(res['document_data']);
          }
        });
      } else {
        this.apiRequest.getAffiliateDocumentStatus(this.customerId, this.loanId).subscribe(res => {
          if (res && res['document_data']) {
            this.setUploadedDocStatus(res['document_data']);
          }
        });
      }
    }
    // this.startCallingEligibilityAPI();
    // this.callEligibilityAPI();
  }

  checkExpectedLoanAmount(form: FormGroup) {
    if (form.get('expected_amount') && form.get('expected_amount').value > 1000000) {
      return { expectedLoanAmountMoreError: true };
    } else {
      return null;
    }
  }

  checkExpectedLoanAmountMultiple(form: FormGroup) {
    if (form.get('expected_amount') && (+form.get('expected_amount').value) % 5000 != 0) {
      return { expectedLoanAmountMultipleError: true };
    } else {
      return null;
    }
  }

  customerArchivedForm() {
    const sample: LeadData = {
      social_email_id: this.socialEmailId,
      first_name: null,
      last_name: null,
      pan: this.pan,
      loan_id: null,
      customer_id: null,
      present_state: null,
      present_stage: null,
      approved_amount: null,
      approved_tenure: null,
      approved_emi: null,
      underwritter_comment: null,
      attribution_status_id: null
    };
    this.mapCustomerDataToForm(sample);
    this.kycFormGroup.controls.pan.disable();
    this.kycFormGroup.controls.social_email_id.disable();
  }

  /*
    Code to find invalid entries from a form group
  */ 

  // public findInvalidControls() {
  //   const invalid = [];
  //   const controls = this.kycFormGroup.controls;
  //   for (const name in controls) {
  //       if (controls[name].invalid) {
  //           invalid.push(name);
  //       }
  //   }
  //   console.log('this are the invalid controls', invalid);
  // }

  saveCustomerData() {

    const postData = this.getPostData();
    console.log('this is the postData', postData)
    console.log('this is the kycForm', this.kycFormGroup)

    if (!this.isFormDataValid()) {
      return;
    }
    if (this.isArchived) {
        this.shareService.showSpinner();
        const checkArchiving = {
          social_email_id: this.socialEmailId,
          pan: this.pan,
        };
        this.apiRequest.checkLead(checkArchiving).subscribe((custDataResponse: DedupeResponse[]) => {
          if (custDataResponse && custDataResponse.length) {
            if (custDataResponse.length === 1) {
              const custData = custDataResponse[0];
              if (custData && custData.customer_loan_archive_eligibility) {
                delete postData['customer_id'];
                delete postData['loan_id'];

                postData['social_email_id'] = this.socialEmailId;
                postData['pan'] = this.pan;

                this.apiRequest.createCustomer(postData).subscribe(response => {
                  this.shareService.hideSpinner();
                  this.toastr.success('Lead Created & Data Saved', 'Success');
                  this.customerId = response['customer_id'];
                  this.loanId = response['loan_id'];
                  this.router.navigate([`lead/${this.customerId}/${this.loanId}`]);
                }, err => {
                  this.shareService.hideSpinner();
                  this.toastr.danger('Error while saving data', 'Error');
                });
              } else {
                this.redirectingUserToHomePage();
              }
            } else {
              this.redirectingUserToHomePage();
            }
          }
        });
    } else {
      this.shareService.showSpinner();
      this.apiRequest.updateCustomerData(postData).subscribe(response => {
        this.shareService.hideSpinner();
        this.disablePersonalDetailsField();
        this.mapCustomerDataToForm(response);
        this.toastr.success("Data Saved", 'Success');
        if(this.presentStage !== "active_type_3") {
          this.updateCustomerDetailSubmitState();
        }
      });
    }
  }

  submitBankDetails() {
    const postData = this.getPostData();
    console.log('this is the postData', postData)
    console.log('this is the kycForm', this.kycFormGroup)

    if (!this.isFormDataValid()) {
      return;
    }
    if (this.isArchived) {
        this.shareService.showSpinner();
        const checkArchiving = {
          social_email_id: this.socialEmailId,
          pan: this.pan,
        };
        this.apiRequest.checkLead(checkArchiving).subscribe((custDataResponse: DedupeResponse[]) => {
          if (custDataResponse && custDataResponse.length) {
            if (custDataResponse.length === 1) {
              const custData = custDataResponse[0];
              if (custData && custData.customer_loan_archive_eligibility) {
                delete postData['customer_id'];
                delete postData['loan_id'];

                postData['social_email_id'] = this.socialEmailId;
                postData['pan'] = this.pan;

                this.apiRequest.createCustomer(postData).subscribe(response => {
                  this.shareService.hideSpinner();
                  this.toastr.success('Lead Created & Data Saved', 'Success');
                  this.customerId = response['customer_id'];
                  this.loanId = response['loan_id'];
                  this.router.navigate([`lead/${this.customerId}/${this.loanId}`]);
                }, err => {
                  this.shareService.hideSpinner();
                  this.toastr.danger('Error while saving data', 'Error');
                });
              } else {
                this.redirectingUserToHomePage();
              }
            } else {
              this.redirectingUserToHomePage();
            }
          }
        });
    } else {
      this.shareService.showSpinner();
      this.apiRequest.updateCustomerData(postData).subscribe(response => {
        this.shareService.hideSpinner();
        this.removeBankingSubmitButton = true;
        this.disableBankingDetailsField();
        this.mapCustomerDataToForm(response);
        this.toastr.success("Data Saved", 'Success');
        // this.updateCustomerDetailSubmitState();
      });
    }
  }

  disableBankingDetailsField() {
    this.bankDetailsFormGroup.controls.account_holder_full_name.disable();
    this.bankDetailsFormGroup.controls.account_number.disable();
    this.bankDetailsFormGroup.controls.ifsc.disable();
    this.bankDetailsFormGroup.controls.branch.disable();
    this.bankDetailsFormGroup.controls.city.disable();
    this.bankDetailsFormGroup.controls.district.disable();
    this.bankDetailsFormGroup.controls.bank_code.disable();
    this.bankDetailsFormGroup.controls.bank.disable();
  }

  saveBankDetails() {
    const postData = this.getPostData();
  }

  disablePersonalDetailsField() {
    this.kycFormGroup.controls.first_name.disable();
    this.kycFormGroup.controls.last_name.disable();
    this.kycFormGroup.controls.mobile_number1.disable();
    this.kycFormGroup.controls.dob.disable();
    this.kycFormGroup.controls.social_email_id.disable();
    this.kycFormGroup.controls.pan.disable();
    this.kycFormGroup.controls.gender.disable();
    this.kycFormGroup.controls.current_pincode.disable();
    this.kycFormGroup.controls.current_city.disable();
    this.kycFormGroup.controls.current_state.disable();
    this.kycFormGroup.controls.salary.disable(); 
    this.kycFormGroup.controls.salary_payment_mode_id.disable();
    this.kycFormGroup.controls.profession_type.disable();
  }

  private redirectingUserToHomePage() {
    this.toastr.danger('The lead cannot be archived!', 'Not Archivable');
    this.router.navigateByUrl('');
    return;
  }

  private getPostData() {
    let postData = {
      customer_id: this.customerId,
      loan_id: this.loanId,
      salary: this.kycFormGroup.value.salary,
      dob: this.kycFormGroup.value.dob,
      salary_payment_mode_id: this.kycFormGroup.value.salary_payment_mode_id,
      employment_status_id: this.professionFormGroup.value.employment_status_id,
      organization_type_id: this.professionFormGroup.value.organization_type_id,
      company: this.professionFormGroup.value.company,
      work_email_id: this.professionFormGroup.value.work_email_id,
      profession_type_id: this.professionFormGroup.value.profession_type_id,
      total_work_experience_category_id: this.professionFormGroup.value.total_work_experience_category_id,
      current_employment_tenure_category_id: this.professionFormGroup.value.current_employment_tenure_category_id,
      current_company_address_line1: this.professionFormGroup.value.current_company_address_line1,
      current_company_address_line2: this.professionFormGroup.value.current_company_address_line2,
      current_company_pincode: this.professionFormGroup.value.current_company_pincode,
      current_company_city: this.professionFormGroup.getRawValue().current_company_city,
      current_company_state: this.professionFormGroup.getRawValue().current_company_state,
      pan: this.kycFormGroup.value.pan,
      first_name: this.kycFormGroup.value.first_name,
      social_email_id: this.kycFormGroup.value.social_email_id,
      last_name: this.kycFormGroup.value.last_name,
      qualification_type_id: this.kycFormGroup.value.qualification_type_id,
      marital_status_id: this.kycFormGroup.value.marital_status_id,
      gender: this.kycFormGroup.value.gender,
      aadhaar: this.kycFormGroup.value.aadhaar,
      mobile_number1: this.kycFormGroup.value.mobile_number1 || undefined,
      current_address_line1: this.kycFormGroup.value.current_address_line1,
      current_address_line2: this.kycFormGroup.value.current_address_line2,
      current_city: this.kycFormGroup.getRawValue().current_city,
      current_state: this.kycFormGroup.getRawValue().current_state,
      current_pincode: this.kycFormGroup.value.current_pincode,
      current_residence_type_id: this.kycFormGroup.value.current_residence_type_id,
      current_residence_stay_category_id: this.kycFormGroup.value.current_residence_stay_category_id,
      account_holder_full_name: this.bankDetailsFormGroup.value.account_holder_full_name,
      account_number: this.bankDetailsFormGroup.value.account_number,
      ifsc: this.bankDetailsFormGroup.value.ifsc,
      branch: this.bankDetailsFormGroup.value.branch,
      city: this.bankDetailsFormGroup.value.city,
      district: this.bankDetailsFormGroup.value.district,
      state: this.bankDetailsFormGroup.value.state,
      contact: this.bankDetailsFormGroup.value.contact,
      bank_code: this.bankDetailsFormGroup.value.bank_code,
      bank: this.bankDetailsFormGroup.value.bank,
      purpose_id: this.bankDetailsFormGroup.value.purpose_id,
      expected_amount: this.bankDetailsFormGroup.value.expected_amount,
      father_first_name: this.additionalDataFormGroup.value.father_first_name,
      father_last_name: this.additionalDataFormGroup.value.father_last_name,
      mother_first_name: this.additionalDataFormGroup.value.mother_first_name,
      mother_last_name: this.additionalDataFormGroup.value.mother_last_name,
      references: this.getReferenceDetails(),
    };
    return postData;
  }

  getReferenceDetails() {
    const reference = [];
    for (let i = 1; i < 3; i++) {
      let obj = {
        id: this.additionalDataFormGroup.value['id_' + i],
        reference_relationship_type_id: this.additionalDataFormGroup.value['reference_relationship_type_id_' + i],
        reference_full_name: this.additionalDataFormGroup.value['reference_full_name_' + i],
        reference_mobile_number: this.additionalDataFormGroup.value['reference_mobile_number_' + i],
        reference_city: this.additionalDataFormGroup.value['reference_city_' + i],
      };
      if (!obj.id) {
        delete obj.id;
      }

      Object.keys(obj).forEach(key => obj[key] === undefined ? delete obj[key] : {});

      if (Object.keys(obj).length > 0) {
        reference.push(obj);
      }

    }
    return reference.length > 0 ? reference : undefined;
  }

  getIfscData() {
    if (this.bankDetailsFormGroup.value.ifsc && this.bankDetailsFormGroup.value.ifsc.length === 11) {
      this.apiRequest.getIfscData(this.bankDetailsFormGroup.value.ifsc).subscribe((response: RazorpayIfscResponse) => {
        this.bankDetailsFormGroup.patchValue({
          ifsc: response.IFSC,
          branch: response.BRANCH,
          city: response.CITY,
          district: response.DISTRICT,
          state: response.STATE,
          contact: response.CONTACT,
          bank_code: response.BANKCODE,
          bank: response.BANK
        });
      });
      this.bank_branch_details = true;
    }
  }


  getCurrentAddressPincodeData(pincode) {
    if (!this.isAffiliate) {
      this.apiRequest.getPincodeData(pincode).subscribe((response: PincodeResponse) => {
        if (response && response.id) {
          this.kycFormGroup.patchValue({
            current_city: response.city,
            current_state: response.state
          });
          this.kycFormGroup.controls.current_city.disable();
          this.kycFormGroup.controls.current_state.disable();
        }
      });
    }
  }

  getOfficeAddressPincodeData(pincode) {
    if (!this.isAffiliate) {
      this.apiRequest.getPincodeData(pincode).subscribe((response: PincodeResponse) => {
        if (response && response.id) {
          this.professionFormGroup.patchValue({
            current_company_city: response.city,
            current_company_state: response.state
          });
          this.professionFormGroup.controls.current_company_city.disable();
          this.professionFormGroup.controls.current_company_state.disable();
        }
      });
    }
  }

  mapCustomerDataToForm(custData: { [key: string]: any }) {
    for (let [field, value] of Object.entries(custData)) {

      if (field === 'references' && value.length >= 1) {
        for (let i = 1; i <= value.length; i++) {
          let obj = value[i - 1];
          for (let [field, value] of Object.entries(obj)) {
            custData[field + '_' + i] = value;
          }
        }
      }
      if (value === null) {
        delete custData[field];
      }
    }


    this.professionFormGroup.patchValue({
      salary: custData.salary,
      salary_payment_mode_id: custData.salary_payment_mode_id,
      employment_status_id: custData.employment_status_id,
      organization_type_id: custData.organization_type_id,
      company: custData.company,
      work_email_id: custData.work_email_id,
      profession_type_id: custData.profession_type_id,
      total_work_experience_category_id: custData.total_work_experience_category_id,
      current_employment_tenure_category_id: custData.current_employment_tenure_category_id,
      current_company_address_line1: custData.current_company_address_line1,
      current_company_address_line2: custData.current_company_address_line2,
      current_company_pincode: custData.current_company_pincode,
      current_company_city: custData.current_company_city,
      current_company_state: custData.current_company_state,
    });
    this.kycFormGroup.patchValue({
      pan: custData.pan,
      first_name: custData.first_name,
      last_name: custData.last_name,
      dob: custData.dob,
      qualification_type_id: custData.qualification_type_id,
      marital_status_id: custData.marital_status_id,
      gender: custData.gender,
      aadhaar: custData.aadhaar,
      mobile_number1: custData.mobile_number1,
      current_address_line1: custData.current_address_line1,
      current_address_line2: custData.current_address_line2,
      current_city: custData.current_city,
      current_state: custData.current_state,
      current_pincode: custData.current_pincode,
      current_residence_type_id: custData.current_residence_type_id,
      current_residence_stay_category_id: custData.current_residence_stay_category_id,
      salary: custData.salary,
      salary_payment_mode_id: custData.salary_payment_mode_id,
      profession_type_id: custData.profession_type_id,
    });
    this.bankDetailsFormGroup.patchValue({
      account_holder_full_name: custData.account_holder_full_name,
      account_number: custData.account_number,
      ifsc: custData.ifsc,
      branch: custData.branch,
      city: custData.city,
      district: custData.district,
      state: custData.state,
      contact: custData.contact,
      bank_code: custData.bank_code,
      bank: custData.bank,
      purpose_id: custData.purpose_id,
      expected_amount: custData.expected_amount,
    });

    if (custData.ifsc == '') {

      this.bank_branch_details = false;
    }
    else {
      this.bank_branch_details = true;
    }

    this.additionalDataFormGroup.patchValue({
      father_first_name: custData.father_first_name,
      father_last_name: custData.father_last_name,
      mother_first_name: custData.mother_first_name,
      mother_last_name: custData.mother_last_name,
      id_1: custData.id_1,
      reference_relationship_type_id_1: custData.reference_relationship_type_id_1,
      reference_full_name_1: custData.reference_full_name_1,
      reference_mobile_number_1: custData.reference_mobile_number_1,
      reference_city_1: custData.reference_city_1,
      id_2: custData.id_2,
      reference_relationship_type_id_2: custData.reference_relationship_type_id_2,
      reference_full_name_2: custData.reference_full_name_2,
      reference_mobile_number_2: custData.reference_mobile_number_2,
      reference_city_2: custData.reference_city_2
    });
    if (custData.social_email_id) {
      this.kycFormGroup.patchValue({
        social_email_id: custData.social_email_id
      });
    }
  }

  // Document upload 
  openFile(file: File | FileError, docType: number): void {

    if (this.isAffiliate) {
      this.apiRequest.fetchS3UrlForDocumentViewForAffiliate(this.customerId, this.loanId, docType).subscribe((response) => {
        if (response.document) {
          const url = response.document;
          const password = response.password;
          const dialogRef = this.dialog.open(ViewDocDialog, {
            height: '90vh',
            data: {
              docUrl: url,
              password: password
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            return;
          });
        } else {
          this.toastr.danger('Some Error took place', 'Error');
          return;
        }
      }, (error) => {
        this.toastr.danger('Some Error took place', 'Error');
        return;
      });

    } else {
      this.apiRequest.fetchS3UrlForDocumentView(this.customerId, this.loanId, docType).subscribe((response) => {
        if (response.document) {
          const url = response.document;
          const password = response.password;
          const dialogRef = this.dialog.open(ViewDocDialog, {
            height: '90vh',
            data: {
              docUrl: url,
              password: password
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            return;
          });
        } else {
          this.toastr.danger('Some Error took place', 'Error');
          return;
        }
      }, (error) => {
        this.toastr.danger('Some Error took place', 'Error');
        return;
      });
    }

  }

  selectFile(file: File | FileError, docType: number): void {
    if (!(file instanceof File)) {
      this.alertError(file);
      return;
    }
    if (!this.docsToUpload[docType]) {
      this.docsToUpload[docType] = {};
    } else {
      this.docsToUpload[docType]['isUploading'] = false;
      this.docsToUpload[docType]['uploaded'] = false;
    }
    this.currentDocType = docType;
    this.filePassword = '';
    this.docsToUpload[docType]['file'] = file;
    this.checkPassword();
  }

  checkPassword(isPasswordFilled = false) {
    let currentFile = this.docsToUpload[this.currentDocType]['file'];
    let docExtension = currentFile.name.split('.').pop();
    if (docExtension.toLowerCase().includes('pdf')) {
      pdfjsLib.getDocument({ 'url': URL.createObjectURL(currentFile), 'password': this.filePassword }).then(response => {
        // this.dialogService.open(this.passwordModal);
        // this.openPasswordDialog();
        this.docPasswordObj[this.currentDocType] = this.filePassword;
        this.filePassword = '';
        this.docUploadTask(this.currentDocType);
      }).catch(err => {
        if (err && err.name === 'PasswordException') {
          this.openPasswordDialog(isPasswordFilled);
          // this.dialogService.open(this.passwordModal);
        }
      })
    } else {
      this.docUploadTask(this.currentDocType);
    }
  }

  private openPasswordDialog(isPasswordWrong = false): void {
    const dialogRef = this.dialog.open(PasswordDialog, {
      width: '400px',
      data: { fileName: this.docsToUpload[this.currentDocType]['file']['name'], filePassword: this.filePassword, isPasswordInvalid: isPasswordWrong }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filePassword = result;
        this.checkPassword(true);
      } else {
        this.docsToUpload[this.currentDocType] = {};
        this.currentDocType = null;
      }

      // console.log('The dialog was closed');
    });
  }

  docUploadTask(docTypeId: number) {
    // console.log(docForm);
    if (!this.docsToUpload[docTypeId] || !this.docsToUpload[docTypeId]['file']) {
      this.toastr.danger('Please select file first');
      return;
    }
    if (this.docsToUpload[docTypeId] && this.docsToUpload[docTypeId]['isUploading']) {
      this.toastr.danger('File upload already in progress');
      return;
    }
    // this['uploaded'] = false;
    this.docsToUpload[docTypeId]['uploaded'] = false;
    let docExtension = '.' + this.docsToUpload[docTypeId]['file']['name'].split('.').pop();
    let password = this.docPasswordObj[docTypeId];

    if (this.isAffiliate) {
      this.apiRequest.getDocUploadUrlForAffiliate(this.customerId, this.loanId, docTypeId, docExtension, password).subscribe(response => {
        if (response && response['document'] != null && response['documemnt'] !== '') {
          let upUrl = response['document'];
          this.finalUpload(upUrl, docTypeId);
        } else {
          retry(1);
          this.toastr.danger('Error while uploading, Please try again');
        }
      });
    } else {
      this.apiRequest.getDocUploadUrl(this.customerId, this.loanId, docTypeId, docExtension, password).subscribe(response => {
        if (response && response['document'] != null && response['documemnt'] !== '') {
          let upUrl = response['document'];
          this.finalUpload(upUrl, docTypeId);
        } else {
          retry(1);
          this.toastr.danger('Error while uploading, Please try again');
        }
      });
    }
  }

  finalUpload(upUrl: string, docTypeId: number) {
    let url = upUrl;
    let reader = new FileReader();
    reader.readAsArrayBuffer(this.docsToUpload[docTypeId]['file']);
    reader.onloadend = (ev) => {
      let base64data = reader.result;
      this.docsToUpload[docTypeId]['isUploading'] = true;
      this.apiRequest.uploadFileToUrl(url, base64data, this.docsToUpload[docTypeId]).subscribe(response => {
        if (response) {
          this.docsToUpload[docTypeId]['isUploading'] = false;
          this.updateDocUploadStatus(docTypeId);
        }
      })
      //let imgRes = this.agentService.uploadImage(url, base64data);
    };
  }

  updateDocUploadStatus(docTypeId: number) {
    let status = this.docsToUpload[docTypeId]['uploaded'] ? 'file_updation_success' : 'file_creation_success';

    if (this.isAffiliate) {
      this.apiRequest.updateDocStatusForAffiliate(this.customerId, this.loanId, docTypeId, status).subscribe(response => {
        if (response['status_updated']) {
          this.docsToUpload[docTypeId]['uploaded'] = true;
          this.allReuiredDocUploaded = this.isRequiredDocsUploaded();
          this.updateCustomerDocumentSubmitState();
        }
      });
    } else {
      this.apiRequest.updateDocStatus(this.customerId, this.loanId, docTypeId, status).subscribe(response => {
        if (response['status_updated']) {
          this.docsToUpload[docTypeId]['uploaded'] = true;
          this.allReuiredDocUploaded = this.isRequiredDocsUploaded();
          this.updateCustomerDocumentSubmitState();
        }
      });
    }
  }

  checkCustomerDetailSubmit() {
    let requiredKeys = ['salary', 'salary_payment_mode_id', 'employment_status_id',
      'company', 'work_email_id', 'profession_type_id', 'total_work_experience_category_id', 'current_employment_tenure_category_id',
      'pan', 'first_name', 'social_email_id', 'last_name', 'gender', 'aadhaar', 'mobile_number1', 'dob',
      , 'current_city', 'current_state', 'current_pincode',
      'current_residence_type_id', 'current_residence_stay_category_id', 'purpose_id', 'expected_amount'];
    for (let field of requiredKeys) {
      if (this.kycFormGroup.get(field) && this.kycFormGroup.get(field).invalid) {
        return false;
      }
      // } else if (this.professionFormGroup.get(field) && this.professionFormGroup.get(field).invalid) {
      //   return false;
      // } else if (field == 'expected_amount' && (+this.bankDetailsFormGroup.get('expected_amount').value > 1000000 || +this.bankDetailsFormGroup.get('expected_amount').value % 5000 != 0)) {
      //   return false;
      // }
    }
    return true;
  }

  completeLoanApplication() {
    if (!this.isFormDataValid()) {
      return;
    }

    if (this.adminId == 26 || this.adminId == 18) {
      if (!this.isRequiredDocsUploaded()) {
        this.toastr.danger("All required documents not uploaded", "Document Error");
        return;
      }
      this.updateApplicationCompleteState();
      return;
    }

    // save data first 
    let postData = this.getPostData();
    this.shareService.showSpinner();
    this.apiRequest.updateCustomerData(postData).subscribe(response => {
      this.shareService.hideSpinner();
      this.mapCustomerDataToForm(response);
      // remove below code.. looks redundant
      if (this.kycFormGroup.valid && this.bankDetailsFormGroup.valid) {
        if (!this.isRequiredDocsUploaded()) {
          this.toastr.danger("All required documents not uploaded", "Document Error");
        } else {
          // check customer state and update
          //check for customer detail submit; Code DEBT
          if (this.checkCustomerDetailSubmit() && ['unknown', 'logged_in'].includes(this.presentState)) {
            this.shareService.showSpinner();
            this.apiRequest.markCustomerDetailSubmitState(this.customerId, this.loanId).subscribe((response: CustomerDetailSubmitStateResponse) => {
              if (response) {
                this.presentState = response.present_state;
              }
              this.shareService.hideSpinner();
              if (this.isScreen2DocsUploaded() && this.presentState === 'customer_detail_submit') {
                this.shareService.showSpinner();
                this.apiRequest.markDocumentSubmitState(this.customerId, this.loanId).subscribe(response => {
                  this.shareService.hideSpinner();
                  if (response && response['all_document_collected'] && response['customer_data']) {
                    this.presentState = response['customer_data']['present_state'];
                  }
                  if (this.presentState === 'document_submit') {
                    this.updateApplicationCompleteState();
                  }
                });
              }
            });
          } else if (this.isScreen2DocsUploaded() && this.presentState === 'customer_detail_submit') {
            this.shareService.showSpinner();
            this.apiRequest.markDocumentSubmitState(this.customerId, this.loanId).subscribe(response => {
              this.shareService.hideSpinner();
              if (response && response['all_document_collected'] && response['customer_data']) {
                this.presentState = response['customer_data']['present_state'];
              }
              if (this.presentState === 'document_submit') {
                this.updateApplicationCompleteState();
              }
            });
          } else if (this.presentState === 'document_submit') {
            this.updateApplicationCompleteState();
          }
        }
      }
    });
  }

  completeLoanApplication2() {
    let postData = this.getPostData();
    if (!this.isFormDataValid()) {
      return;
    }
    this.shareService.showSpinner();
    this.apiRequest.updateCustomerData(postData).subscribe(response => {
      this.shareService.hideSpinner();
      this.mapCustomerDataToForm(response);

      if (!this.isRequiredDocsUploaded()) {
        this.toastr.danger("All required documents not uploaded", "Document Error");
        return;
      }

      if(this.presentState == 'document_submit') {
        this.updateApplicationCompleteState();
      }


    })
  }

  uploadBankStatement() {
    if (!this.isRequiredDocsUploaded()) {
      this.toastr.danger("Bank Statement not uploaded", "Document Error");
      return;
    }

    if (this.presentState === 'customer_detail_submit') {
      this.shareService.showSpinner();
      this.apiRequest.markDocumentSubmitStateBureau(this.customerId, this.loanId).subscribe(response => {
        this.shareService.hideSpinner();
        console.log('this is the response')
        if(response) {
          this.isBankDocumentUploaded = true;
          this.presentState = response.data.customer_data.present_state
          this.presentStage = response.data.customer_data.present_stage
          console.log('this is the present stage', this.presentStage)
          // this.callBankingEligibilityAPI();
        }
      })
    }
  }

  alertError(msg: FileError) {
    switch (msg) {
      case FileError.NumError:
        this.toastr.danger('Number Error');
        break;
      case FileError.SizeError:
        this.toastr.danger('File size issue, max allowed 8 MB');
        break;
      case FileError.TypeError:
        this.toastr.danger('Please select correct file type');
        break;
    }
  }

  private isFormDataValid(): boolean {
    if (this.kycFormGroup.invalid) {
      this.toastr.danger('Kyc Data fields are incorrect/not filled', 'Data Error');
      return;
    }
    // if (this.professionFormGroup.invalid) {
    //   this.toastr.danger('Profession Data fields are incorrect/not filled', 'Data Error');
    //   return;
    // }
    // if (this.bankDetailsFormGroup.invalid) {
    //   this.toastr.danger('Bank and Other Data fields are incorrect/not filled', 'Data Error');
    //   return;
    // }
    // if (!this.bankDetailsFormGroup.get('expected_amount').value || +this.bankDetailsFormGroup.get('expected_amount').value > 1000000 || +this.bankDetailsFormGroup.get('expected_amount').value % 5000 != 0) {
    //   this.toastr.danger('Expected Loan Amount incorrect/not filled', 'Data Error');
    //   return;
    // }
    return true;
  }

  private isRequiredDocsUploaded(): boolean {
    for (let documentTypeId of this.requiredDocs) {
      if (!this.docsToUpload[documentTypeId] || !this.docsToUpload[documentTypeId]['uploaded']) {
        return false;
      }
    }
    return true;
  }
  private isRequiredDocsUploaded2(): boolean {
    for (let documentTypeId of this.requiredDocs2) {
      if (!this.docsToUpload[documentTypeId] || !this.docsToUpload[documentTypeId]['uploaded']) {
        return false;
      }
    }
    return true;
  }

  private isScreen2DocsUploaded(): boolean {
    for (let documentTypeId of this.androidScreen2Docs) {
      if (!this.docsToUpload[documentTypeId] || !this.docsToUpload[documentTypeId]['uploaded']) {
        return false;
      }
    }
    return true;
  }

  private updateCustomerDetailSubmitState() {
    if (this.checkCustomerDetailSubmit() && ['unknown', 'logged_in'].includes(this.presentState)) {
      this.apiRequest.markCustomerDetailSubmitState(this.customerId, this.loanId).subscribe((response: CustomerDetailSubmitStateResponse) => {
        if (response) {
          this.presentState = response.present_state;
          this.startCallingEligibilityAPI();
          location.reload();
        }
      });
    }
  }

  private updateCustomerDocumentSubmitState() {
    if (this.isAffiliate) {
      if (this.isScreen2DocsUploaded() && this.presentState === 'customer_detail_submit') {
        this.apiRequest.markDocumentSubmitStateForAffiliate(this.customerId, this.loanId).subscribe(response => {
          if (response && response['all_document_collected'] && response['customer_data']) {
            this.presentState = response['customer_data']['present_state'];
            this.toastr.success('Application submitted successfully', 'Application Complete');
            this.router.navigateByUrl('');
          } else {
            this.toastr.danger('Some error took place. Please try again.', 'Error');
          }
        }, error => {
          this.toastr.danger('Some error took place. Please try again.', 'Error');
        });
      }
    } else {
      if (this.isScreen2DocsUploaded() && this.presentState === 'customer_detail_submit') {
        this.apiRequest.markDocumentSubmitState(this.customerId, this.loanId).subscribe(response => {
          if (response && response['all_document_collected'] && response['customer_data']) {
            this.presentState = response['customer_data']['present_state'];
          }
        });
      }
    }
  }

  private setUploadedDocStatus(docUploadedObj: { [key: string]: string }) {
    for (let [docTypeId, status] of Object.entries(docUploadedObj)) {
      if (this.docsToUpload[docTypeId] && appConstants.docUploadSuccessStatuses.includes(status)) {
        this.docsToUpload[docTypeId]['uploaded'] = true;
      }
    }
  }

  private updateApplicationCompleteState() {
    this.shareService.showSpinner();
    this.apiRequest.markLoanApplicationComplete(this.customerId, this.loanId, this.isAffiliate).subscribe(res => {
      this.shareService.hideSpinner();
      this.toastr.success('Application submitted successfully', 'Application Complete');
      // if (!appConstants.editingAllowedStates.includes(res['present_state'])) {
      //   this.isApplicationComplete = true;
      // }
      this.router.navigate(['lead', 'new']);
    })

  }

  scrollToSectionHook(event: StepperSelectionEvent) {
    const element = document.querySelector('.mat-stepper-vertical');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth', block: 'start', inline:
            'nearest'
        });
        console.log('scrollIntoView');
      }, 250);
    }
  }

  pdfViewerReady($event: any) {
    console.log($event);
  }

  startTimer() {
    this.showTimerScreen = true;
    let totalSeconds = 10; 
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    this.remainingTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    totalSeconds--;
    
    this.interval = setInterval(() => {
      minutes = Math.floor(totalSeconds / 60);
      seconds = totalSeconds % 60;
      this.remainingTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      totalSeconds--;
      
      if (totalSeconds < 0) {
        console.log('inside ')
        this.remainingTime = "";
        this.timerOver = true;
        
        clearInterval(this.interval);
        // Timer has reached 0, perform desired action here
      }
    }, 1000);
  }

  
  ngAfterViewInit() {
    // this.startTimer();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    
    if (this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
    }
  }

  startCallingEligibilityAPI(): void {
    this.startTimer();
    console.log('startCallingEligibilityAPI')
    this.apiCallSubscription = timer(0, 10000)
      .subscribe(() => {
        this.callEligibilityAPI();
      });

    // Stop API calls after 2 minutes
    setTimeout(() => {
      console.log('setTimeOUT')
      this.apiCallSubscription.unsubscribe();
      clearInterval(this.interval);
      this.onEligibilityAPIEnd()
    }, 10000);
  }

  // callBankingEligibilityAPI() {
  //   this.apiRequest.fetchBankingEligibility(this.customerId, this.loanId)
  //   .subscribe((response) => {
  //     if(response && response.data && response.data.is_eligible) {
  //       this.offerAmount = response.data.banking_data.loan_amount;
  //       this.offerTenure = response.data.banking_data.loan_tenure;
  //     }
  //   })
  // }

  callEligibilityAPI(): void {
    this.apiRequest.fetchUnderwritingEligibility(this.customerId, this.loanId)
      .subscribe((response: any) => {
        this.apiResponseObject = response;
        console.log('this is the eligibility response',this.apiResponseObject)
        this.applicationStatus = response.data.credit_decision_status;
        if(this.apiResponseObject && this.apiResponseObject.data && this.apiResponseObject.data.credit_decision_status == 'completed') {
          this.showTimerScreen = false;
          this.apiCallSubscription.unsubscribe();
          clearInterval(this.interval);
          if(this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.is_eligible &&
            !this.apiResponseObject.data.credit_decision_completed_data.bureau_banking_program_data.is_eligible) {
            console.log('bureau program working')
            this.showBureau = true;
            this.offerAmount = this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.offer.loan_amount
            this.offerTenure = this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.offer.loan_tenure
          } else if (!this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.is_eligible &&
            this.apiResponseObject.data.credit_decision_completed_data.bureau_banking_program_data.is_eligible) {
            console.log('bureau + banking program working')
            this.showBureauBanking = true;
            this.bankVerificationRequired = true;
            this.offerAmount = this.apiResponseObject.data.credit_decision_completed_data.aip_program_data.offer.loan_amount
            this.offerTenure = this.apiResponseObject.data.credit_decision_completed_data.aip_program_data.offer.loan_tenure
          } else if (this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.is_eligible && 
            this.apiResponseObject.data.credit_decision_completed_data.bureau_banking_program_data.is_eligible) {
              console.log('Both are working');
              this.offerAmount = this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.offer.loan_amount
              this.offerTenure = this.apiResponseObject.data.credit_decision_completed_data.bureau_program_data.offer.loan_tenure
              this.showBureauBankingBoth = true;
              this.bankVerificationRequired = true;
            }
        } else if (response && response.data && response.data.credit_decision_status == 'processing') {
          this.showTimerScreen = false;
         
        } else if (response && response.data && response.data.credit_decision_status == 'rejected') {
          this.showTimerScreen = false;
          // show the rejected screen
          clearInterval(this.interval)
          this.showTimerScreen = false;
          console.log('comming till here3')
          this.apiCallSubscription.unsubscribe();
          this.showRejectedScreen = true;
          this.showTimerScreen = false;
        } else if (response && response.data && response.data.credit_decision_status == 'failed') {
          this.apiCallSubscription.unsubscribe();
          clearInterval(this.interval);
          // show default data
          //banking data
        }
      });
  }

  onEligibilityAPIResponse() {
    console.log('onEligibilityAPIResponse')
  }

  onEligibilityAPIEnd() {
    console.log('onEligibilityAPIEnd')
    
   if (this.apiResponseObject && this.apiResponseObject.data && this.apiResponseObject.data.credit_decision_status == 'processing') {
      this.offerAmount = this.apiResponseObject.data.default_data.loan_amount;
      this.offerTenure = this.apiResponseObject.data.default_data.loan_tenure;
      console.log('comming till here')
      if(this.timerOver) {
        console.log('comming till here2')
        this.showBureau = true;
        this.showBankingDetails = true;
        this.bankVerificationRequired = true;
      }
    }
  }

  onProceedWithBureauClicked(stepper) {
    console.log('onProceedWithBureauClicked')
    this.showBureau = true;
    this.showBureauBanking = false;
  }
  
  onProceedWithBankClicked() {
    console.log('onProceedWithBankClicked')
    this.showBureau = false;
    this.showBureauBanking = true;
  }
}

// this.stepper.selectionChange.subscribe((event) => { this.scrollToSectionHook(event.selectedIndex); });


// For password dialog box

@Component({
  selector: 'password-dialog',
  templateUrl: 'password-dialog.html',
})
export class PasswordDialog {
  constructor(
    public dialogRef: MatDialogRef<PasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordDialogData) { }

  passwordCancel(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  docUrl: string,
  password: string,
}

@Component({
  selector: 'view-doc-dialog',
  templateUrl: 'view-doc-dialog.html',
})
export class ViewDocDialog {
  isImg = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public sanitizer: DomSanitizer) {
    let fileExt = data.docUrl.match(/.*\.([a-zA-Z]{3,5})?.*/)[1];
    if (fileExt.toLowerCase() === 'pdf') {
      this.isImg = false;
    } else {
      this.isImg = true;
    }
  }
}



