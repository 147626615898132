import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  loader: EventEmitter<boolean> = new EventEmitter();
  isAffiliateUser: EventEmitter<boolean> = new EventEmitter();
  showSEPLjourney = new Subject<boolean>();

  panAndEmail = new BehaviorSubject({});

  constructor() { }
  showSpinner() {
    this.loader.emit(true);
  }
  hideSpinner() {
    this.loader.emit(false);
  }

  setPanAndEmail(data: any) {
    this.panAndEmail.next(data);
  }

  // setSEPLjourney(data: any) {
  //   setInterval(() => this.showSEPLjourney.next(data), 2000);
  //   localStorage.setItem('showSEPL', 'showSEPL')
  // }

}
export function getBaseLocation() {
  let paths: string[] = location.pathname.split('/');
  let basePath = '/';
  if (paths.includes('affiliate')) {
    basePath = '/affiliate';
  }
  return basePath;
}
