import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, ActivationEnd } from '@angular/router';
import { BreadCrumb } from '../shared/interfaces';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs$;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadCrumb(this.activatedRoute.root)),
      startWith(this.buildBreadCrumb(this.activatedRoute.root))
    );
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '',
    breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'Dashboard';
    const path = route.routeConfig ? route.routeConfig.path : '/';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };

    let newBreadcrumbs;
    if (label != null) {
      newBreadcrumbs = [...breadcrumbs, breadcrumb];
    } else {
      newBreadcrumbs = breadcrumbs;
    }

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

}
