import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Associate, LoginResponse } from 'src/app/shared/interfaces';
import { ApiRequestService } from '../shared/api-request.service';
// import { NbToastrService } from '@nebular/theme';
import { DeviceDetectorService } from 'ngx-device-detector';
import { appConstants } from '../shared/constants';
import { forkJoin } from 'rxjs';
import { ShareService } from '../shared/share.service';
import { Router } from '@angular/router';

declare const gapi: any;
interface lendingkartOtpLoginForm {
  lendingkartEmailId: string;
  lendingkartOtp?: string;
}
interface lendingkartOtpSentResponse {
  lendingkart_otp_sent_successfully: boolean;
  lendingkart_role?: string;
}


@Component({
  selector: 'app-lendingkartlogin',
  templateUrl: './lendingkartlogin.component.html',
  styleUrls: ['./lendingkartlogin.component.scss']
})
export class LendingkartLoginComponent implements OnInit {
  lendingkartOtpLoginEnabled = false;
  lendingkartIsOtpSent = false;
  lendingkartEmailRegex = appConstants.regexes.email;
  lendingkartIsInvalidEmail: boolean;
  lendingkartAssociateEmail: string;
  lendingkartLoading = false;
  lendingkartIsInvalidOtp: boolean;
  lendingkartClientIp: string;
  lendingkartActionLogId: number;
  lendingkartVersion = appConstants.version;

  constructor(
    private authService: AuthService,
    private ngZone: NgZone,
    private apiRequest: ApiRequestService,
    // private toastr: NbToastrService,
    private deviceService: DeviceDetectorService,
    private shareService: ShareService,
    private router: Router,
  ) { }

  ngOnInit() {
    localStorage.setItem('Login-URL', this.router.url);
    this.apiRequest.logAdminAction(this.lendingkartGetInitialLogObj()).subscribe((res: object) => {
      this.lendingkartClientIp = res['client_ip'];
      this.lendingkartActionLogId = res['id'];
    });
      
  }
  ngAfterViewInit() {
    if (gapi && gapi.signin2) {
      googleRenderer.call(this);
    } else {
      setTimeout(googleRenderer.bind(this), 1500)
    }
    function googleRenderer() {
      if (!gapi || !gapi.signin2) {
        // this.toastr.danger('Page not loaded correctly, Please refresh', 'Load Error');
        alert("Page not loaded correctly, Please reload")
      }
      gapi.signin2.render('my-signin3', {
        'scope': 'profile email',
        'width': 240,
        'height': 50,
        'left': 'auto',
        'right': 'auto',
        'longtitle': true,
        'theme': 'light',
        'onsuccess': param => this.lkOnSignIn(param)
      });
    }

  }
  lkOnSignIn(googleUser) {
    this.authService.setGoogleAuthInstance(gapi.auth2.getAuthInstance());
    let profile = googleUser.getBasicProfile();
    let idToken = googleUser.getAuthResponse().id_token;
    this.ngZone.run(() => this.lkGoogleSignIn(idToken))
    /* console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail());  */
    // This is null if the 'email' scope is not present.
  }

  lkGoogleSignIn(googleToken: string) {
    // login and admin action log requests
    let loginRequest = this.apiRequest.associateLogin(googleToken).subscribe(
      (associateLoginRes: LoginResponse) => {
        if (associateLoginRes.is_valid_user && associateLoginRes.is_authenticated) {
          this.authService.login(associateLoginRes.profile);
        }
        // let combinedData = { ...adminLogRes, ...associateLoginRes }
        
        this.lendingkartUpdateLoginAdminData(associateLoginRes, false);
      }, err => {
        this.lendingkartUpdateLoginAdminData(err, true);
      });
  }  

  lendingkartGetOtp(formData) {
    this.lendingkartAssociateEmail = formData.lendingkartEmailId || this.lendingkartAssociateEmail;
    this.lendingkartIsOtpSent = false;
    this.shareService.showSpinner();
    this.lendingkartLoading = true;
    this.apiRequest.getOtp(this.lendingkartAssociateEmail).subscribe((res: LoginResponse) => {
      this.shareService.hideSpinner();     
      this.lendingkartLoading = false;
      if (res.is_valid_user) {
        this.lendingkartIsOtpSent = true;
        this.lendingkartIsInvalidEmail = false;
      } else {
        // this.toastr.danger("Email is not authorised, Please check email or Contact us");
        this.lendingkartIsInvalidEmail = true;
        this.lendingkartIsOtpSent = false;
      }
    }, err => {
      this.lendingkartLoading = false;
    })
  }

  lendingkartLoginViaOtp(formData: lendingkartOtpLoginForm) {   
    const otp = formData.lendingkartOtp;
    this.shareService.showSpinner();
    this.lendingkartLoading = true;
    this.apiRequest.otpBasedLogin(otp, this.lendingkartAssociateEmail).subscribe((associateLoginRes: LoginResponse) => {
      this.shareService.hideSpinner();
      this.lendingkartLoading = false;     
      if (!associateLoginRes.is_authenticated) {
        this.lendingkartIsInvalidOtp = true;
      } else if (associateLoginRes.is_valid_user && associateLoginRes.is_authenticated) {
        this.authService.login(associateLoginRes.profile);   
        this.lendingkartUpdateLoginAdminData(associateLoginRes, false);
        this.lendingkartIsInvalidOtp = false;
      }
    }, err => {
      this.lendingkartLoading = false;     
      this.lendingkartUpdateLoginAdminData(err, true);
    })
  }

  private lendingkartGetInitialLogObj() {
    let deviceInfo = this.lendingkartGetDeviceInfo();
    let dateTime = new Date(new Date().valueOf() - new Date().getTimezoneOffset() * 60000).toISOString();
    let preData = {
      user_type: 'Associate',
      action: appConstants.lkAdminActions.login,
      action_start_datetime: dateTime,
      action_start_status_id: appConstants.adminActionStatus.started,
      device_type: deviceInfo['deviceType'],
      product_type_id: appConstants.productType,
      client: deviceInfo['browser'],
      client_version: deviceInfo['browser_version'],
      client_os: deviceInfo['os'],
    }
    return preData;
  }


  private lendingkartUpdateLoginAdminData(adminData: object, isFailure = false) {
    // let loginIP = adminData['client_ip'];
    let dateTime = new Date(new Date().valueOf() - new Date().getTimezoneOffset() * 60000).toISOString();
    let postData = {
      action_end_datetime: dateTime,
      action_end_status_id: isFailure ? appConstants.adminActionStatus.failure : appConstants.adminActionStatus.successful
    }   
    this.apiRequest.getIpAddressData(this.lendingkartClientIp).subscribe((res) => {
      if (res && res['status'] === 'success') {
        postData['client_latitude'] = res['lat'];
        postData['client_longitude'] = res['lon'];
        postData['client_zipcode'] = res['zip'];
        postData['isp'] = res['isp'];
        postData['city'] = res['city'];
        postData['country'] = res['country'];
      }

      postData['user_id'] = adminData['profile'] ? adminData['profile']['associate_user_id'] : '';
      postData['email_id'] = adminData['profile'] ? adminData['profile']['email_id'] : '';
      postData['user_name'] = adminData['profile']['name'];
      postData['id'] = this.lendingkartActionLogId;    
      if (this.lendingkartActionLogId) {       
        this.apiRequest.updateAdminActionLog(postData).subscribe((res) => { 
          
        });
      } 
    });
  }
  
  private lendingkartGetDeviceInfo() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    let deviceType = appConstants.deviceType.desktop;
    if (this.deviceService.isTablet()) {
      deviceType = appConstants.deviceType.tablet;
    } else if (this.deviceService.isMobile()) {
      deviceType = appConstants.deviceType.mobile;
    }
    deviceInfo['deviceType'] = deviceType;
    return deviceInfo;
  } 
 

}

